import "../../Styles/invoicing.scss";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Watermark } from "@hirohe/react-watermark";
import {
  Snackbar,
  Card,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Typography,
  Divider,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";

import {
  faAddressBook,
  faArrowLeftLong,
  faDownload,
  faEnvelope,
  faMailBulk,
  faMailReply,
  faMoneyBill,
  faPhone,
  faTimes,
  faUserAlt,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../Util/Loading";
import logo from "../../assets/images/logo.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Input from "../Util/Input";
import {
  ArrowBack,
  ArrowForward,
  Close,
  CreditCard,
  Delete,
  Download,
  Edit,
  Email,
  Mail,
  Phone,
} from "@mui/icons-material";

export default function PreviewInvoice(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [paid, setPaid] = useState(0);
  const [payments, setPayments] = useState(null);
  const [currency, setCurrency] = useState("KSh");
  const [customer, setCustomer] = useState(null);
  const [bdata, setBdata] = useState([]);
  const [pbody, setPBody] = useState({
    InvoiceID: null,
    Customer: null,
    Date: null,
    Amount: null,
  });
  const [user, setUser] = useState(null);
  const params = useParams();
  const [generated, setGenerated] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);
  const id = window.location.search.split("=")[1];
  const navigate = useNavigate();
  console.log(id);
  const [messages, setMessages] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [descriptionItems, setDescriptionItems] = useState([]);

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  useEffect(() => {
    fetch(`/api/invoices/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setData(data);
        if (data) {
          let d = pbody;
          d.InvoiceID = data.ID;
          setPBody(d);
          setGenerated(data?.Status);
        }
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    if (data) {
      fetch(`/api/invitems/byinvoiceid/${data?.ID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setBdata(data);
        })
        .catch((e) => {});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      fetch(`/api/mobile/${data?.CustomerID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          let d = pbody;
          d.Customer = data?.Name;
          setPBody(d);
          setCustomer(data);
        })
        .catch((e) => {});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      fetch(`/api/auth/${data?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setUser(data);
        })
        .catch((e) => {});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      fetch(`/api/payments/getallbyinvoiceid/${data?.ID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setPayments(data);
          let d = 0;

          data.map((i) => {
            d += parseInt(i.Amount);
          });
          setPaid(d);
        })
        .catch((e) => {});
    }
  }, [data, refresh]);

  useEffect(() => {
    if (data) {
      fetch(`/api/invoices/${data.ID}/messages`)
        .then((res) => res.json())
        .then((messages) => {
          setMessages(messages);
        })
        .catch((e) => {});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      fetch(`/descriptionitems/invoice/${data.ID}`)
        .then((res) => res.json())
        .then((items) => {
          setDescriptionItems(items);
        })
        .catch((e) => {
          console.error("Failed to fetch description items:", e);
        });
    }
  }, [data]);

  const downloadPdfDocument = () => {
    const invoice = document.getElementById("invoice");
    const descriptionPage = document.getElementById("description-page");
    const footer = document.querySelector(".invfooter");

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    Promise.all([
      html2canvas(invoice, { scale: 2 }),
      descriptionItems.length > 0
        ? html2canvas(descriptionPage, { scale: 2 })
        : null,
      html2canvas(footer, { scale: 2 }),
    ]).then(([invoiceCanvas, descCanvas, footerCanvas]) => {
      // First page (Invoice)
      const invoiceAspectRatio = invoiceCanvas.width / invoiceCanvas.height;
      const invoiceWidth = pdfWidth - 20; // 10mm margin on each side
      const invoiceHeight = invoiceWidth / invoiceAspectRatio;
      const invoiceY = 10; // Top margin

      pdf.addImage(
        invoiceCanvas.toDataURL("image/png"),
        "PNG",
        10,
        invoiceY,
        invoiceWidth,
        invoiceHeight
      );

      // Add footer to first page
      const footerAspectRatio = footerCanvas.width / footerCanvas.height;
      const footerWidth = pdfWidth - 20;
      const footerHeight = footerWidth / footerAspectRatio;
      const footerY = pdfHeight - footerHeight - 10;

      pdf.addImage(
        footerCanvas.toDataURL("image/png"),
        "PNG",
        10,
        footerY,
        footerWidth,
        footerHeight
      );

      // Second page (Description List) if exists
      if (descCanvas) {
        pdf.addPage();

        const descAspectRatio = descCanvas.width / descCanvas.height;
        const descWidth = pdfWidth - 20;
        const descHeight = descWidth / descAspectRatio;
        const descY = 10;

        pdf.addImage(
          descCanvas.toDataURL("image/png"),
          "PNG",
          10,
          descY,
          descWidth,
          descHeight
        );

        // Add footer to second page
        pdf.addImage(
          footerCanvas.toDataURL("image/png"),
          "PNG",
          10,
          footerY,
          footerWidth,
          footerHeight
        );
      }

      pdf.save(`${customer?.Name}.pdf`);
    });
  };

  const sendPdfDocument = () => {
    const invoice = document.getElementById("invoice");

    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the image dimensions for the PDF
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      // if (imgHeight > pdfHeight) {
      //   imgHeight = pdfHeight;
      //   imgWidth = pdfHeight * aspectRatio;
      // }

      // Center the image in the PDF
      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0; // Start from the top for the main content

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Now handle the footer separately
      // Capture the footer separately
      const footer = document.querySelector(".invfooter");

      html2canvas(footer, { scale: 2 }).then((footerCanvas) => {
        const footerImgData = footerCanvas.toDataURL("image/png");

        // Calculate the dimensions of the footer image
        const footerAspectRatio = footerCanvas.width / footerCanvas.height;
        let footerImgWidth = imgWidth;
        let footerImgHeight = imgWidth / footerAspectRatio;

        if (footerImgHeight > pdfHeight) {
          footerImgHeight = pdfHeight;
          footerImgWidth = pdfHeight * footerAspectRatio;
        }

        // Add footer image at the bottom
        const footerYOffset = pdfHeight - footerImgHeight; // Bottom position

        pdf.addImage(
          footerImgData,
          "PNG",
          0,
          footerYOffset,
          footerImgWidth,
          footerImgHeight
        );
        const blb = pdf.output(`blob`);
        sendData(blb, `${customer?.Name}`);
      });
    });
  };

  function sendData(pdf, downloadFileName) {
    const formData = new FormData();
    formData.append("Invoice", pdf, `${downloadFileName}.pdf`);
    formData.append("Email", customer?.Email);
    formData.append("Name", customer?.Name);
    formData.append("CUserID", props.user?.UserID);

    fetch(`/api/invoices/sendemail/${data?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setError(data.success);
          setMessages(data.messageCount);
          setTimeout(() => {
            setError(null);
          }, 1000);
        } else {
          setError(data.error);
          setTimeout(() => {
            setError(null);
          }, 1000);
        }
      })
      .catch((e) => {});
  }

  const deleteInvoice = () => {
    fetch(`/api/invoices/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed to delete invoice");
      })
      .then((data) => {
        navigate("/invoices"); // Redirect to invoices list after deletion
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className="invoicing">
      <div className="container">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="title">{customer?.Name} Invoice</Typography>
          <Button
            variant="outlined"
            onClick={() => {
              window.location.href = "/invoices";
            }}
            startIcon={<ArrowForward />}
          >
            Invoices
          </Button>
        </Box>
        <div className="divsplit">
          <div className="inv_preview">
            <div id="invoice" className="invoice">
              <div className="bar">
                <div className="invtop">
                  <img src={logo} alt="" />
                  <p>Vedic House, 5th Floor,</p>
                  <p>North wing, Room 509/510,</p>
                  <p>P.O. Box 20252-00100.</p>
                </div>
                <h1>INVOICE</h1>
                <div className="div"></div>
              </div>
              <div className="invheader">
                <div className="left">
                  <div className="enclose">
                    <h4>Invoice to:</h4>
                    <hr />
                    <div className="div1auto">
                      <h4>Name: </h4>
                      <p> {customer?.Name}</p>
                    </div>
                    <div className="div1auto">
                      <h4>LPO No: </h4>
                      <p>{data?.Title}</p>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <Box
                    display="flex"
                    gap={1}
                    mb={1}
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" fontSize={11}>
                      Due:
                    </Typography>
                    <Typography variant="body2" fontSize={11}>
                      {data?.Date}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap={1}
                    mb={1}
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" fontSize={11}>
                      No:
                    </Typography>
                    <Typography variant="body2" fontSize={11}>
                      {data?.InvoiceNo}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap={1}
                    mb={1}
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" fontSize={11}>
                      PIN:
                    </Typography>
                    <Typography variant="body2" fontSize={11}>
                      P051588439V
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap={1}
                    mb={1}
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" fontSize={11}>
                      By:
                    </Typography>
                    <Typography variant="body2" fontSize={11}>
                      {user?.Name}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    gap={1}
                    mb={1}
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" fontSize={11}>
                      Date:
                    </Typography>
                    <Typography variant="body2" fontSize={11}>
                      {data?.createdAt.split("T")[0]}
                    </Typography>
                  </Box>
                </div>
              </div>
              <Box px={4} py={2}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontSize: 12, fontWeight: "bold" }}>
                          Description
                        </TableCell>
                        <TableCell sx={{ fontSize: 12, fontWeight: "bold" }}>
                          Quantity
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          Rate
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bdata &&
                        bdata.map((item, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell
                                sx={{
                                  fontSize: 12,
                                  textTransform: "capitalize",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.Item}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: 12,
                                  textAlign: "center",
                                }}
                              >
                                {item.Qty}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: 12,
                                  textAlign: "right",
                                }}
                              >
                                {(item.Rate - 0).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: 12,
                                  textAlign: "right",
                                }}
                              >
                                {(item.Rate * item.Qty).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box
                py={2}
                px={4}
                display="flex"
                gap={1}
                justifyContent="space-between"
              >
                <Stack spacing={1} flexGrow={3}>
                  <Typography variant="body2" fontSize={12}>
                    Thank you for your business
                  </Typography>

                  <Typography variant="body2" fontSize={12}>
                    This is a system generated invoice. No signature or stamp is
                    required.
                  </Typography>
                </Stack>
                <Stack flexGrow={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap={2}
                    mb={1}
                  >
                    <Typography variant="body2" fontSize={11}>
                      Sub Total
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      fontSize={12}
                    >
                      {data &&
                        (data?.SubTotal - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap={2}
                    mb={1}
                  >
                    <Typography variant="body2" fontSize={11}>
                      16% VAT
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize={12}
                      fontWeight={"bold"}
                    >
                      {data &&
                        (data?.VAT - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" gap={2}>
                    <Typography variant="body2" fontSize={11}>
                      Total ({customer?.Currency ? customer?.Currency : "KSh"})
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={"bold"}
                      fontSize={12}
                    >
                      {data &&
                        (data?.Total - 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <div className="payment">
                <h4>Payment Details</h4>
                <div className="div2equal">
                  <div>
                    <h4>Bank Transfer/Cheque</h4>
                    <p>A/C Name: Arope Group Limited</p>
                    <p>Account No.: 1200515226</p>
                    <p>SWIFT Code: KCBLKENX</p>
                    <p>Bank: KCB Bank, Karen; Bank Code: 1</p>
                  </div>
                  <div>
                    <h4>M-PESA Paybill </h4>
                    <p>A/C Name: Arope Group Limited</p>
                    <p>Business No.: 709 777</p>
                    <p>Account Name: {customer?.Name}</p>
                  </div>
                </div>
              </div>
            </div>

            {descriptionItems.length > 0 && (
              <div
                id="description-page"
                style={{
                  marginTop: "50px",
                  pageBreakBefore: "always",
                }}
              >
                <DescriptionItemsPage
                  items={descriptionItems}
                  customer={customer}
                  data={data}
                  user={user}
                />
              </div>
            )}

            <div className="invfooter">
              <div className="fbars">
                <div></div>
                <h6>Arope Group LTD</h6>
                <div></div>
              </div>
              <div className="contacts">
                <p>
                  <Phone icon={faPhone} /> +254 754 709 777
                </p>
                <p>
                  <Phone icon={faPhone} />
                  +254 726 709 777
                </p>
                <p>
                  <Email icon={faAddressBook} /> info@aropegroup.com
                </p>
              </div>
            </div>
            {!generated && (
              <Watermark
                wrapperStyle={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 999,
                }}
                text={`This is a preview invoice`}
              ></Watermark>
            )}
          </div>
          <div className="iright_panel">
            <div className="links">
              <button
                onClick={() => {
                  sendPdfDocument();
                }}
              >
                <Mail className="ic" icon={faEnvelope} />
                {messages.length === 0
                  ? "Send Invoice"
                  : `Send Reminder (${messages.length})`}
              </button>
              {error && <h6>{error}</h6>}
              <button
                onClick={() => {
                  downloadPdfDocument();
                }}
              >
                <Download className="ic" icon={faDownload} /> Download Invoice
              </button>
              <button
                onClick={() => {
                  setCreating(true);
                }}
              >
                <CreditCard className="ic" icon={faMoneyBill} /> Add Payment
              </button>
              <button
                onClick={() => {
                  navigate(`/invoices/edit/${id}`);
                }}
              >
                <Edit className="ic" /> Edit Invoice
              </button>
              <button
                onClick={() => {
                  deleteInvoice();
                }}
              >
                <Delete className="ic" /> Delete Invoice
              </button>
            </div>

            <div className="inv_stats">
              <div className="tp">
                <div className="lf">
                  <p>Paid</p>
                  <h4>
                    {paid.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  </h4>
                </div>
                <div className="rf">
                  <p>Currency</p>
                  <p>KSh</p>
                </div>
              </div>
              <div className="div2equal">
                <p>Balance</p>
                <h5>
                  {data
                    ? (data.Total - paid).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })
                    : "0.00"}
                </h5>
              </div>
            </div>
            <div className="c_payments">
              <h3>Payments</h3>
              <hr />
              <div>
                {payments &&
                  payments.length > 0 &&
                  payments.map((item, i) => {
                    return (
                      <PaymentItem
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setLoading={setLoading}
                        key={i}
                        item={item}
                        index={i}
                      />
                    );
                  })}
              </div>
              {creating && (
                <CreatePayment
                  body={pbody}
                  setCreating={setCreating}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                />
              )}
            </div>
            <Card sx={{ marginTop: 2, padding: 2 }}>
              <Typography variant="title">Messages</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              <div>
                {messages &&
                  messages.length > 0 &&
                  messages.map((message, i) => (
                    <Card key={i} sx={{ marginBottom: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 1,
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {dayjs(message.createdAt).format("DD/MM/YYYY HH:mm")}
                        </Typography>
                        <IconButton
                          onClick={() => handleExpandClick(i)}
                          aria-expanded={expanded[i]}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </Box>

                      <Collapse in={expanded[i]} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: 1 }}>
                          <Typography paragraph>{message.Message}</Typography>
                        </Box>
                      </Collapse>
                    </Card>
                  ))}
              </div>
            </Card>
          </div>
        </div>

        {!generated && (
          <CreateInvoice
            refresh={refresh}
            setRefresh={setRefresh}
            setLoading={setLoading}
            id={id}
          />
        )}
        {loading && <Loading />}
      </div>
    </div>
  );
}

const BillingItem = (props) => {
  return (
    <div className="tprow">
      <h2>{props?.item?.Item}</h2>
      <h2>{props?.item?.Qty}</h2>
      <h2>
        {(props?.item?.Rate - 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </h2>
      <h2>
        {(props?.item?.Rate * props?.item?.Qty).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </h2>
    </div>
  );
};

const PaymentItem = (props) => {
  function deleteItem() {
    fetch(`/api/payments/${props.item.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        window.location.reload();
      })
      .catch((e) => {});
  }
  return (
    <div className="pitem">
      <div className="no">{props.index + 1}</div>
      <div>
        <h4>{props?.item?.Customer}</h4>
        <p>{props?.item?.Date}</p>
      </div>

      <h5>
        KSh{" "}
        {(props?.item?.Amount - 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </h5>
      <Close
        onClick={() => {
          deleteItem();
        }}
        className="fa-times"
        icon={faTimes}
      />
    </div>
  );
};

const CreateInvoice = (props) => {
  function createInv() {
    props.setLoading(true);
    fetch(`/api/invoices/${props.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Status: true }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setRefresh(!props.refresh);
        props.setLoading(false);
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }

  return (
    <div className="createinv">
      <div className="wrap">
        <h1>Generate Invoice</h1>
        <p>
          You must generate an invoice before you can download or send the
          invoice
        </p>
        <button
          onClick={() => {
            createInv();
          }}
        >
          Accept
        </button>
        <h4
          onClick={() => {
            window.location.href = "/invoices";
          }}
        >
          Cancel
        </h4>
      </div>
    </div>
  );
};

const CreatePayment = (props) => {
  const [body, setBody] = useState(props.body);
  const date = useRef();
  const amount = useRef();

  function createInv() {
    if (date.current.value == "" || amount.current.value == "") return;
    let d = body;
    d.Date = date.current.value;
    d.Amount = amount.current.value;

    props.setLoading(true);
    fetch(`/api/payments/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setCreating(false);
        props.setRefresh(!props.refresh);
        props.setLoading(false);
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }

  return (
    <div className="createinv">
      <div className="wrap">
        <h1>Create Payment</h1>
        <hr />
        <br />
        <Input
          ref={date}
          label="Date"
          type="date"
          value={new Date().toISOString().split("T")[0]}
        />
        <Input ref={amount} label="Amount" type="number" />
        <button
          onClick={() => {
            createInv();
          }}
        >
          Accept
        </button>
        <Close
          icon={faTimes}
          className="fa-times"
          onClick={() => {
            props.setCreating(false);
          }}
        />
      </div>
    </div>
  );
};

const DescriptionItemsPage = ({ items, customer, data, user }) => {
  return (
    <div className="invoice">
      <div className="bar">
        <div className="invtop">
          <img src={logo} alt="" />
        </div>
        <h4>Invoice No: {data?.InvoiceNo}</h4>
        <div className="div"></div>
      </div>
      <Box px={4} py={2}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: 12, fontWeight: "bold" }}>
                  No.
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: "bold" }}>
                  Name
                </TableCell>
                <TableCell sx={{ fontSize: 12, fontWeight: "bold" }}>
                  ID Number
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={item.ID}>
                  <TableCell sx={{ fontSize: 12 }}>{index + 1}</TableCell>
                  <TableCell sx={{ fontSize: 12 }}>{item.Name}</TableCell>
                  <TableCell sx={{ fontSize: 12 }}>{item.IdNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};
