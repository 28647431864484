import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  Box,
  Button,
  Divider,
  Alert,
  Grid2,
  Paper,
  Chip,
} from "@mui/material";
import logo from "../assets/images/logo.png";
import Loading from "../components/Util/Loading";
import WaveLoading from "../components/Util/WaveLoading";

const UberVerification = () => {
  const id = window.location.pathname.split("/")[2];
  const [driverData, setDriverData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log(id);

  useEffect(() => {
    fetch(`/api/uberreport/getuberid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.length > 0) {
          setDriverData(data[0]);
        } else {
          setError("Driver details not found!");
        }
        setLoading(false);
      })
      .catch((e) => {
        setError("Failed to fetch driver data");
        setLoading(false);
      });
  }, [id]);

  return (
    <Box>
      <Header />
      <Container sx={{ my: 2 }}>
        <Card sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Box sx={{ display: "flex", flex: "1 auto" }}>
              <Typography
                sx={{ flexGrow: 1 }}
                variant="h5"
                color="primary"
                gutterBottom
              >
                Uber Driver Verification
              </Typography>
              <Chip label={driverData?.Date}></Chip>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Grid2 container spacing={3}>
              <Grid2 size={{ xs: 12, md: 8 }}>
                <Typography variant="body1" gutterBottom>
                  <strong style={{ fontWeight: "500" }}>Name:</strong>{" "}
                  {`${driverData?.FirstName} ${driverData?.MiddleName} ${driverData?.Surname}`.trim()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong style={{ fontWeight: "500" }}>ID Number:</strong>{" "}
                  {driverData?.NationalID}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong style={{ fontWeight: "500" }}>Phone Number:</strong>{" "}
                  {driverData?.Phone}
                </Typography>

                <ResultItem
                  label="Identity Check"
                  check={driverData?.IDCheck}
                  value="The National ID Card is Authentic"
                />
                <ResultItem
                  label="PSV Check (Drivers only)"
                  check={driverData?.PSVCheck}
                  value="Driving license details match PSV details"
                />
                <ResultItem
                  label="Driving License Check"
                  check={driverData?.DLCheck}
                  value="The Driving License is authentic"
                />
                <ResultItem
                  label="Police Check"
                  check={driverData?.PoliceCheck}
                  value="Nil criminal records"
                />
              </Grid2>

              <Paper
                sx={{
                  display: "grid",
                  placeContent: "center",
                  p: 5,
                  my: 2,
                  mx: "auto",
                  backgroundColor: "#F0F0EF",
                  borderRadius: "8px",
                }}
                xs={12}
                md={4}
                maxWidth
                item
              >
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "900", width: "100%" }}
                  color={
                    driverData?.Result == "Pass"
                      ? "green"
                      : driverData?.Result == "Fail"
                      ? "red"
                      : "orange"
                  }
                >
                  {driverData?.Result}
                </Typography>
              </Paper>
            </Grid2>
            {error && (
              <Alert sx={{ my: 2, borderRadius: "5px" }} color="warning">
                {error}
              </Alert>
            )}
            <Divider sx={{ my: 1 }} />

            <Button
              disabled={
                driverData != null && driverData.Report != null ? false : true
              }
              onClick={() => {
                // window.open(
                //   `https://api.aropegroup.com/api/uploads/${driverData.Report}`,
                //   "_blank"
                // );
                window.open(
                  `http://api.aropegroup.com/api/uploads/${driverData.Report}`,
                  "_blank"
                );
              }}
              sx={{ margin: "auto", display: "block" }}
              variant="contained"
              color="primary"
            >
              View Original Certificate
            </Button>
          </CardContent>
        </Card>
      </Container>
      {loading && <WaveLoading />}
    </Box>
  );
};

export default UberVerification;

const ResultItem = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        my: 1,
        border: "1px solid #60606050",
        p: "8px",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        flex: "1 1",
      }}
      container
      spacing={2}
      variant="body1"
      gutterBottom
    >
      <Typography
        variant="body1"
        style={{
          my: "auto",
          display: "block",
          fontWeight: "400",
          flexGrow: 1,
        }}
      >
        {props.label}
      </Typography>{" "}
      <Box>
        {props?.check?.includes(props?.value) ? (
          <Chip
            item
            xs={6}
            sx={{ px: 5, fontSize: "small" }}
            label="Pass"
            color="success"
          ></Chip>
        ) : (
          <Chip
            item
            xs={6}
            sx={{ px: 5, fontSize: "small" }}
            label="Failed"
            color="warning"
          ></Chip>
        )}
      </Box>
    </Box>
  );
};

const Header = () => {
  return (
    <AppBar position="static">
      <Container disableGutters>
        <Toolbar sx={{ display: "flex", flex: "auto 1 auto", gap: 3 }}>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img style={{ maxHeight: "44px" }} src={logo} alt="" />
          </Box>
          <Typography sx={{ width: "100%" }} variant="h6">
            Arope Group
          </Typography>
          <Button
            variant="outlined"
            sx={{
              color: "white",
              border: "1px solid white",
              borderRadius: "5px",
              minWidth: "150px",
            }}
          >
            Uber Portal
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
