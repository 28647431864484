import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import { Close, Email } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function EditUserDetails({
  open,
  onClose,
  isAuthenticated,
  currentUser,
  setIsAuthenticated,
}) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    Email: "",
    Name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const rfPhone = useRef();
  const rfName = useRef();
  const navigate = useNavigate();

  const editDetails = () => {
    if (isAuthenticated) {
      const d = {
        Email: rfPhone.current.value,
        Name: rfName.current.value,
      };

      if (!d.Name.includes(" ")) {
        return setIsError(
          "Enter a valid name including your Surname and Firstname"
        );
      }
      if (d.Name.length < 3) {
        return setIsError("Name too short");
      }

      setIsLoading(true);
      updateBody(d);
      setIsError("");

      fetch(`/api/auth/${currentUser.UserID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(d),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Change of details failed");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            setIsError(data.success);
            setTimeout(() => {
              localStorage.clear();
              setIsAuthenticated(false);
              navigate("/");
            }, 1000);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError("Update failed!");
        });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Edit Account Details for {currentUser.Name}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isError && (
          <Typography color="error" sx={{ mb: 2 }}>
            {isError}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            inputRef={rfName}
            label="Change Name"
            variant="outlined"
            margin="normal"
            defaultValue={currentUser.Name}
            fullWidth
          />
          <TextField
            inputRef={rfPhone}
            label="Change Email"
            variant="outlined"
            margin="normal"
            type="email"
            defaultValue={currentUser.Email}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={editDetails}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
