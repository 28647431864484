import { useEffect, useRef, useState } from "react";
import "../../Styles/ubertask.scss";
import WaveLoading from "../Util/WaveLoading";
import { BsInfoSquare } from "react-icons/bs";
import { Watermark } from "@hirohe/react-watermark";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import QRCode from "react-qr-code";
import GenericPdfDownloader from "./GenericPDFDownloader";
import {
  Box,
  Button,
  Typography,
  Grid2,
  Card,
  Divider,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  CardContent,
  TextField,
  CardActions,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Close, Edit, HolidayVillage, Update } from "@mui/icons-material";

export default function RiderTask(props) {
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);
  const [showing, setShowing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [jdets, setJDets] = useState(null);
  const [udets, setUDets] = useState(null);
  const [tt, setTT] = useState(null);
  const [ct, setCT] = useState(null);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [rdata, rsetData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [dledit, dlsetEdit] = useState(false);
  const [pcedit, pcsetEdit] = useState(false);
  const refId = useRef();
  const refIdCheck = useRef();
  const refDLCheck = useRef();
  const refPSVCheck = useRef();
  const refPCCheck = useRef();
  const [nameEdit, setNameEdit] = useState(false);
  var jwt = require("jsonwebtoken");
  const [currentUser, setCurrentUser] = useState(null);
  const [pcc, setPcc] = useState("");

  // Refs for Name and National ID
  const refName = useRef();
  const refNationalID = useRef();
  const pccRef = useRef();

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    //setIsLoading(true);

    fetch(`/api/uber/${id}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setJDets(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (jdets) {
      //setIsLoading(true);
      fetch(`/api/uberusers/${jdets?.UserID}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          setUDets(data);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [jdets]);

  useEffect(() => {
    setData(null);
    //setIsLoading(true);
    fetch(`/api/tasks/requestid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          setData(data[0]);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    //setIsLoading(true);
    fetch(`/api/subjects/checks/byjobid/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        if (data.length > 0) {
          let total = 0;
          let count = 0;
          data.map((item) => {
            const it = JSON.parse(item.Checks);
            total = total + it.length;
            it.map((i) => {
              if (!i.Status) {
                count += 1;
              }
            });
          });
          setTT(total);
          setCT(count);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (data && data.Checking === null) {
      fetch(`/api/tasks/${data.ID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ Checking: false }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error();
        })
        .then((data) => {
          setRefresh(!refresh);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (ct === 0 && tt !== 0) {
        updateStatus(true, false);
      }
    }
  }, [tt, ct]);

  function updateStatus(checking, report) {
    //setIsLoading(true);
    fetch(`/api/tasks/${data.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Checking: checking, ReportPreparation: report }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        setRefresh(!refresh);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  function formatAMPM(dt) {
    let date = new Date(Date.parse(dt));
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return dt.split("T")[0] + " " + strTime;
  }

  const Link = (params) => {
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false); // State to handle dialog
    const [fileContent, setFileContent] = useState(null); // To hold file blob URL
    const [fileType, setFileType] = useState(""); // To determine file type (PDF/Image)

    useEffect(() => {
      if (params?.link) {
        getDocument();
      }
    }, [params.link]);

    function getDocument() {
      //setIsLoading(true);
      fetch(`/api/uploads/${params?.link}`)
        .then((res) => {
          if (res.ok) {
            const contentType = res.headers.get("content-type");
            setFileType(contentType);
            return res.blob();
          } else {
            throw new Error("File not found");
          }
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          setFileContent(url);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setFileContent(null);
        });
    }

    return (
      <div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {fileType.includes("image") ? (
              <img
                src={fileContent}
                alt="file content"
                style={{
                  height: "200px",
                  cursor: "pointer",
                  width: "100%",
                  objectFit: "cover",
                }}
                onClick={() => {
                  setOpen(true);
                }}
              />
            ) : fileType.includes("pdf") ? (
              <div
                onClick={() => {
                  setOpen(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <iframe
                  src={fileContent}
                  style={{
                    width: "100%",
                    height: "200px",
                    border: "none",
                    pointerEvents: "none",
                  }}
                  title="PDF Preview"
                />
              </div>
            ) : (
              <Typography variant="body2">Unsupported file format.</Typography>
            )}
          </>
        )}

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            File Preview
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {fileType.includes("image") ? (
              <img
                src={fileContent}
                alt="file content"
                style={{ width: "100%", objectFit: "contain" }}
              />
            ) : fileType.includes("pdf") ? (
              <iframe
                src={fileContent}
                title="PDF Preview"
                style={{ width: "100%", height: "600px", border: "none" }}
              />
            ) : (
              <Typography variant="body2">Unsupported file format.</Typography>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  //report

  useEffect(() => {
    if (jdets && udets) {
      fetch(`/api/uberreport/getuberid/${jdets.DocumentID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data.length > 0) {
            rsetData(data[0]);
            setEdit(data[0].IDCheck != null ? false : true);
            dlsetEdit(data[0].DLCheck != null ? false : true);
            pcsetEdit(data[0].PoliceCheck != null ? false : true);
          } else {
            createUberTask();
          }
        })
        .catch((e) => {});
    }
  }, [jdets, udets, refresh]);

  function createUberTask() {
    const nameParts = udets.Name.split(" ");
    const body = {
      UberID: jdets.DocumentID,
      FirstName: nameParts[0] || "", // First word is FirstName
      MiddleName: nameParts.slice(1, nameParts.length - 1).join(" ") || "", // Concatenate everything except the first and last words
      Surname: nameParts.length > 1 ? nameParts[nameParts.length - 1] : "", // Last word is Surname
      Phone: udets.Phone,
      Result: "Pending",
      Photo: jdets.Passport,
      Category: jdets.Category,
      User: props.user?.Name,
    };

    fetch(`/api/uberreport/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setRefresh(!refresh);
      })
      .catch((e) => {});
  }

  const handleNameEditChange = () => {
    setPcc(pccRef.current.value);
    let names = refName.current.value.split(" ");
    updateReport({
      FirstName: names.length > 0 ? names[0] : null, // First word is FirstName
      MiddleName:
        names.length > 2 ? names.slice(1, names.length - 1).join(" ") : null, // Concatenate everything except the first and last words
      Surname: names.length > 1 ? names[names.length - 1] : null, // Last word is Surname
      NationalID: refNationalID.current.value,
    });
    setNameEdit(false);
  };

  const handleEditChange = (checkRef, setCheckEdit, updateReportField) => {
    updateReport({
      [updateReportField]: checkRef.current.value,
    });
    setCheckEdit(false);
  };

  function updateReport(body) {
    if (!rdata) return;
    fetch(`/api/uberreport/${rdata.UberID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setRefresh(!refresh);
      })
      .catch((e) => {});
  }

  function resetReport() {
    if (!rdata) return;

    fetch(`/api/uberreport/${rdata.UberID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        Date: null,
        Report: null,
        Result: "Pending",
        PoliceCheck: null,
        DLCheck: null,
        IDCheck: null,
        Status: false,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setRefresh(!refresh);
      })
      .catch((e) => {});
  }

  function finishReport() {
    let d = {};
    if (
      rdata &&
      rdata.Status === false &&
      rdata.DLCheck != null &&
      rdata.PoliceCheck != null &&
      rdata.IDCheck != null
    ) {
      if (
        rdata.DLCheck.includes("NOT") ||
        !rdata.PoliceCheck.includes("Nil") ||
        rdata.IDCheck.includes("NOT")
      ) {
        d = {
          Result: "Fail",
          Date: dateNow(),
          Status: true,
        };
      } else {
        d = {
          Result: "Pass",
          Date: dateNow(),
          Status: true,
        };
      }
      fetch(`/api/uberreport/${rdata.UberID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(d),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setRefresh(!refresh);
        })
        .catch((e) => {});
    }
  }

  function dateNow(params) {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "/" + mm + "/" + yyyy;
  }

  return (
    <Box sx={{ position: "relative", mt: 1 }}>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 10,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Grid2 container spacing={3}>
        {/* Basic Info Section */}
        <Grid2 size={{ xs: 12 }}>
          <Typography variant="h6">
            <BsInfoSquare /> Basic Info
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Grid2 container spacing={2}></Grid2>
        </Grid2>

        {/* Job Details */}
        <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
          <Card
            elevation={3}
            sx={{
              padding: "1em",
              height: "100%",
              width: "100%",
              borderRadius: "8px",
              boxShadow: "0px 8px 16px #60606040",
            }}
          >
            <Typography variant="subtitle1">Job Details</Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2">
              <b>Check for:</b> {udets?.Name}
            </Typography>
            <Typography variant="body2">
              <b>Type:</b> {jdets?.Category}
            </Typography>
            <Typography variant="body2">
              <b>Mpesa Code:</b> {jdets?.MPESA}
            </Typography>
          </Card>
        </Grid2>

        {/* Driver Details */}
        <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
          <Card
            elevation={3}
            sx={{
              padding: "1em",
              height: "100%",
              width: "100%",
              borderRadius: "8px",
              boxShadow: "0px 8px 16px #60606040",
            }}
          >
            <Typography variant="subtitle1">Driver Details</Typography>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="body2">
              <b>Name:</b> {udets?.Name}
            </Typography>
            <Typography variant="body2">
              <b>Phone:</b> {udets?.Phone}
            </Typography>
            <Typography variant="body2">
              <b>Email:</b> {udets?.Email}
            </Typography>
          </Card>
        </Grid2>

        {/* Progress */}
        <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
          <Card
            elevation={3}
            sx={{
              padding: "1em",
              height: "100%",
              width: "100%",
              borderRadius: "8px",
              boxShadow: "0px 8px 16px #60606040",
            }}
          >
            <Typography variant="subtitle1">Progress</Typography>
            <Divider sx={{ mb: 1 }} />
            <Item txt="Received" status={true} />
            <Item txt="Actual Checks" status={rdata?.Date != null} />
            <Item txt="Report Submission" status={rdata?.Report != null} />
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <Card
            elevation={3}
            sx={{
              borderRadius: "8px",
              boxShadow: "0px 8px 16px #60606040",
            }}
          >
            <CardContent>
              <Box
                p={0}
                m={0}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle" color="primary">
                  Documents
                </Typography>
                <IconButton
                  sx={{ border: "1px solid gray" }}
                  onClick={() => setNameEdit(!nameEdit)}
                  size="small"
                ></IconButton>
              </Box>
              <Divider sx={{ my: 1 }} />
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Box display="block" gap={2}>
                    <Typography>National ID</Typography>
                    {jdets && <Link index={0} link={jdets?.NationalID} />}
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Box display="block" gap={2}>
                    <Typography>Passport Photo</Typography>
                    {jdets && <Link index={0} link={jdets?.Passport} />}
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <Box display="block" gap={2}>
                    <Typography>Police Clearance Certificate</Typography>
                    {jdets && <Link index={0} link={jdets?.Police} />}
                  </Box>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12 }}>
          <Card
            elevation={3}
            sx={{
              borderRadius: "8px",
              boxShadow: "0px 8px 16px #60606040",
            }}
          >
            <CardContent>
              <Box
                p={0}
                m={0}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle" color="primary">
                  Update Details
                </Typography>
                <IconButton
                  sx={{ border: "1px solid gray" }}
                  onClick={() => setNameEdit(!nameEdit)}
                  size="small"
                >
                  {nameEdit ? (
                    <Close fontSize="small" />
                  ) : (
                    <Edit fontSize="small" />
                  )}
                </IconButton>
              </Box>
              <Divider sx={{ my: 1 }} />
              {nameEdit ? (
                <Box component="form" sx={{ mt: 2 }}>
                  <Grid2 container spacing={2}>
                    {/* Name Field */}
                    <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
                      <TextField
                        inputRef={refName}
                        label="Name"
                        variant="outlined"
                        fullWidth
                        size="small"
                        defaultValue={
                          rdata?.FirstName +
                            " " +
                            rdata?.MiddleName +
                            " " +
                            rdata?.Surname || ""
                        }
                      />
                    </Grid2>
                    {/* National ID Field */}
                    <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
                      <TextField
                        inputRef={refNationalID}
                        label="National ID"
                        variant="outlined"
                        fullWidth
                        size="small"
                        defaultValue={rdata?.NationalID || ""}
                      />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
                      <TextField
                        inputRef={pccRef}
                        label="PCC"
                        variant="outlined"
                        fullWidth
                        size="small"
                        defaultValue={""}
                      />
                    </Grid2>
                  </Grid2>
                  <Box sx={{ mt: 2, textAlign: "right" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleNameEditChange}
                      sx={{ mr: 1 }}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => setNameEdit(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2">
                    <b>Name:</b>{" "}
                    {rdata?.FirstName +
                      " " +
                      rdata?.MiddleName +
                      " " +
                      rdata?.Surname}
                  </Typography>
                  <Typography variant="body2">
                    <b>ID No.:</b> {rdata?.NationalID || "N/A"}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid2>

        {/* Checks Section Header */}
        <Grid2 size={{ xs: 12 }}>
          <Typography variant="h6">
            <BsFillPersonCheckFill /> Checks
          </Typography>
          <Divider />
        </Grid2>

        {/* Check Items */}
        <CheckItem
          title="Identity"
          check={rdata?.IDCheck}
          editable={edit}
          setEdit={setEdit}
          refInput={refId}
          refSelect={refIdCheck}
          options={[
            "The National ID Card is NOT Authentic",
            "The National ID Card is Authentic",
          ]}
          handleEditChange={() =>
            handleEditChange(refIdCheck, setEdit, "IDCheck")
          }
        />
        <CheckItem
          title="Police"
          check={rdata?.PoliceCheck}
          editable={pcedit}
          setEdit={pcsetEdit}
          refSelect={refPCCheck}
          options={[
            "Criminal record(s) found",
            `Nil criminal records. PCC Code: ${pcc}`,
          ]}
          handleEditChange={() =>
            handleEditChange(refPCCheck, pcsetEdit, "PoliceCheck")
          }
        />
        <CheckItem
          title="License"
          check={rdata?.DLCheck}
          editable={dledit}
          setEdit={dlsetEdit}
          refSelect={refDLCheck}
          options={[
            "The Driving License is NOT authentic",
            "The Driving License is authentic",
          ]}
          handleEditChange={() =>
            handleEditChange(refDLCheck, dlsetEdit, "DLCheck")
          }
        />

        {/* Report Section */}
        {rdata?.Report && (
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="h6">
              <BsFillPersonCheckFill /> Report
            </Typography>
            <Divider sx={{ my: 1 }} />
            {rdata?.Report ? (
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    window.open(
                      `https://api.aropegroup.com/api/uberreport/getfile/${rdata.ID}`,
                      "_blank"
                    )
                  }
                  size="small"
                >
                  View/Download Report
                </Button>
              </Box>
            ) : (
              <Typography sx={{ mt: 2, textAlign: "center" }}>
                Uber Certificate is not ready yet!
              </Typography>
            )}
          </Grid2>
        )}

        <Grid2
          size={{ xs: 12, md: 10 }}
          sx={{ overflowX: "auto" }}
          component={Card}
        >
          {rdata && <UberReport data={rdata} email={udets.Email} />}
        </Grid2>
        <Grid2 size={{ xs: 12, md: 2 }}>
          <Stack spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={finishReport}
              size="small"
              startIcon={<Update />}
            >
              Update
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateReport({
                  Result: "On Hold",
                  Status: true,
                  Date: new Date().toLocaleDateString(),
                });
              }}
              size="small"
              startIcon={<HolidayVillage />}
            >
              On Hold
            </Button>
          </Stack>
          {rdata && (
            <GenericPdfDownloader
              downloadFileName={`${rdata.FirstName}_${rdata.Surname}_UberCert`}
              rootElementId="report"
              id={rdata.ID}
              email={udets.Email}
              name={`${rdata.FirstName} ${rdata.Surname}`}
              refresh={props.refresh}
              setRefresh={props.setRefresh}
            />
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
}

// CheckItem Component
const CheckItem = ({
  title,
  value,
  check,
  editable,
  setEdit,
  refInput,
  refSelect,
  options,
  handleEditChange,
}) => {
  return (
    <Grid2 size={{ xs: 12, md: 4, sm: 6 }}>
      <Card
        elevation={3}
        sx={{
          height: "100%",
          px: 2,
          py: 1,
          borderRadius: "8px",
          boxShadow: "0px 8px 16px #60606040",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={0}
          m={0}
        >
          <Typography variant="body2" color="primary">
            {title}
          </Typography>
          <IconButton onClick={() => setEdit(!editable)} size="small">
            {editable ? <Close fontSize="small" /> : <Edit fontSize="small" />}
          </IconButton>
        </Box>
        <Divider />
        {editable ? (
          <>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel sx={{ fontSize: "small" }}>{title}</InputLabel>
              <Select
                sx={{ fontSize: "small" }}
                inputRef={refSelect}
                label={title}
              >
                {options.map((option) => (
                  <MenuItem
                    sx={{ fontSize: "small" }}
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          <>
            <Typography variant="body2" sx={{ mb: "5px" }}>
              {value}
            </Typography>
            <Typography variant="body2">{check}</Typography>
          </>
        )}

        {editable && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleEditChange}
            sx={{
              ml: "auto",
              fontSize: "small",
              textTransform: "capitalize",
              display: "block",
            }}
          >
            Submit
          </Button>
        )}
      </Card>
    </Grid2>
  );
};

const Item = ({ txt, status }) => {
  const [color, setColor] = useState("red");

  useEffect(() => {
    switch (status) {
      case true:
        setColor("green");
        break;
      case false:
        setColor("orange");
        break;
      case null:
        setColor("red");
        break;
      default:
        setColor("red");
        break;
    }
  }, [status]);

  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          backgroundColor: color,
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      ></Box>
      <Typography variant="body2">{txt}</Typography>
    </Box>
  );
};

const UberReport = (props) => {
  console.log(props);

  return (
    <div className="ubertask">
      <div
        id="report"
        style={{
          margin: "2.5em auto",
        }}
        className="uberreport"
      >
        {props.data && (
          <Watermark
            text={`${props.data.FirstName} ${props.data.Surname}: ${props.data.Result}`}
          >
            <div className="container">
              <div className="utop">
                <div className="logo">
                  <img src={logo} alt="" />
                  <div className="capt">
                    <hr />
                    <h4>UBER RIDER CERTIFICATE</h4>
                    <hr />
                  </div>
                </div>
                <div
                  style={{
                    overflow: "hidden",
                    height: "200px",
                    width: "180px",
                    border: "1px solid #60606030",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`/api/uploads/${props.data.Photo}`}
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "contain",
                      objectPosition: "top",
                      border: "1px solid #60606030",
                      display: "block",
                      margin: "auto",
                    }}
                    alt=""
                  />
                </div>
              </div>
              <br />
              <br />
              <div className="div3equal">
                <div className="ref">
                  <h4>National ID</h4>
                  <p>{props.data.NationalID}</p>
                </div>
                <div className="ref">
                  <h4>Arope Reference No.</h4>
                  <p>
                    {props.data.Category == "New Rider" ? "A/R/" : "A/RN/"}
                    {props.data.SerialNo}/
                    {new Date().getFullYear().toString().slice(2, 4)}/
                    {new Date().getMonth() + 1 < 10
                      ? "0" + (new Date().getMonth() + 1)
                      : new Date().getMonth() + 1}
                  </p>
                </div>
                <div className="ref">
                  <h4>Date</h4>
                  <p>{props.data.Date != null ? props.data.Date : ""}</p>
                </div>
              </div>

              <br />
              <br />
              <h3>Driver Details</h3>
              <div className="rpt">
                <div className="id">
                  <h4>Surname</h4>
                  <p>{props.data.Surname}</p>
                </div>
                <div className="id">
                  <h4>Middle Name</h4>
                  <p>{props.data.MiddleName}</p>
                </div>
                <div className="id">
                  <h4>First Name</h4>
                  <p>{props.data.FirstName}</p>
                </div>
                <div className="id">
                  <h4>Phone No.</h4>
                  <p>{props.data.Phone}</p>
                </div>
              </div>

              <div className="chk">
                <h3>Checks Conducted</h3>
                <div className="id">
                  <h4>Identification Check</h4>
                  <p>
                    {props.data.IDCheck != null
                      ? props.data.IDCheck
                      : "Pending"}
                  </p>
                </div>
                <div className="id">
                  <h4>Driving License Check</h4>
                  <p>
                    {props.data.DLCheck != null
                      ? props.data.DLCheck
                      : "Pending"}
                  </p>
                </div>
                <div className="id">
                  <h4>Police Clearance Certificate Verification</h4>
                  <p>
                    {props.data.PoliceCheck != null
                      ? props.data.PoliceCheck
                      : "Pending"}
                  </p>
                </div>
              </div>

              <div className="passfail">
                <div className="pdiv">
                  <h6
                    style={{
                      color: props.data.Result === "Pass" ? "green" : "orange",
                    }}
                  >
                    {props.data.Result}
                  </h6>
                </div>

                <div className="qrcode">
                  <QRCode
                    size={180}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://admin.aropegroup.com/verify/${props.data.UberID}`}
                    viewBox={`0 0 180 180`}
                  />
                </div>
              </div>
            </div>
          </Watermark>
        )}
      </div>
    </div>
  );
};
