import { useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import {
  Box,
  Typography,
  IconButton,
  Grid2,
  Card,
  Divider,
} from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CustomBarChart from "../Stats/CustomBarChart";
import GaugeChart from "react-gauge-chart";

export default function UberHome() {
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const items = ["New Driver", "Driver Renewal", "New Rider", "Rider Renewal"];

  const fetchData = (item) => {
    fetch(`/api/uber/stats/top/${item}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed to fetch");
      })
      .then((response) => {
        setData((prev) => ({ ...prev, [item]: response }));
      })
      .catch(() => {
        setData((prev) => ({ ...prev, [item]: null }));
      });
  };

  useEffect(() => {
    items.forEach((item) => fetchData(item));
  }, [refresh]);

  const renderSection = (item, dataItem) => (
    <Grid2 key={item} container spacing={3} sx={{ mt: 4 }}>
      <Grid2 size={{ md: 9, xs: 12, sm: 6 }}>
        <Box
          className="bar"
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          <Box
            sx={{ width: "10px", height: "24px", backgroundColor: "orange" }}
          />
          <Typography variant="h6">{item} Tasks</Typography>
        </Box>
      </Grid2>

      <Grid2 size={{ md: 4.5, xs: 12 }}>
        <Box
          component={Card}
          sx={{
            p: 2,
            boxShadow: "0px 8px 16px #60606040",
            borderRadius: "8px",
            height: "100%",
          }}
        >
          <Typography>Monthly Performance</Typography>
          <Divider sx={{ my: 1 }} />
          <CustomBarChart
            aspect={1.8}
            data={dataItem ? dataItem.Bar : null}
            color="orange"
          />
        </Box>
      </Grid2>
      <Grid2 size={{ md: 4.5, xs: 12 }}>
        <Box
          component={Card}
          sx={{
            p: 2,
            boxShadow: "0px 8px 16px #60606040",
            borderRadius: "8px",
            height: "100%",
          }}
        >
          <Typography>Completion Rate</Typography>
          <Divider sx={{ my: 1 }} />
          <GaugeChart
            style={{ marginTop: "1em" }}
            id={`gauge-chart-${item}`}
            nrOfLevels={3}
            colors={["red", "orange", "green"]}
            arcWidth={0.3}
            percent={
              dataItem ? (dataItem.Complete / dataItem.AllTasks).toFixed(2) : 0
            }
            textColor="gray"
          />
        </Box>
      </Grid2>
      <Grid2 size={{ md: 3, xs: 12 }}>
        <Box
          component={Card}
          className="single"
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            p: 2,
            boxShadow: "0px 8px 16px #60606040",
            borderRadius: "8px",
            mb: 2,
          }}
        >
          <AddTaskIcon sx={{ fontSize: "44px", color: "primary.main" }} />
          <Box ml="auto">
            <Typography textAlign="end" variant="h5">
              {dataItem ? dataItem.AllTasks : 0}
            </Typography>
            <Typography textAlign="end" variant="body2">
              Completed: <b>{dataItem ? dataItem.Complete : 0}</b>
            </Typography>
          </Box>
        </Box>
        <Box
          component={Card}
          sx={{
            p: 2,
            boxShadow: "0px 8px 16px #60606040",
            borderRadius: "8px",
          }}
        >
          <Typography textAlign="center" color="primary" variant="h6">
            {dataItem ? dataItem.Overdue : 0}
          </Typography>
          <Typography textAlign="center" variant="body1">
            Overdue
          </Typography>
          <Divider sx={{ my: 1 }} />
          {["1 Day", "2 Days", "3 or More Days"].map((label, idx) => (
            <Box
              key={label}
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <Box
                sx={{
                  width: "10px",
                  height: "16px",
                  backgroundColor: ["green", "orange", "red"][idx],
                }}
              />
              <Typography fontSize="small">{label}: </Typography>
              <Typography fontSize="small">
                {dataItem ? dataItem[`Day${idx + 1}`] : 0}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid2>
    </Grid2>
  );

  return (
    <Box>
      {/* Welcome Section */}
      <Box
        sx={{
          mt: 1,
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr auto" },
          gap: "1em",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h6" color="primary">
            Welcome to the UBER MODULE
          </Typography>
          <Typography display="flex" alignItems="center" variant="body1">
            Let's do our best today <EmojiEmotionsIcon color="warning" />
          </Typography>
        </Box>
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
            color: "primary.main",
            backgroundColor: "white",
            borderRadius: "5px",
            "&:hover": {
              color: "primary.dark",
            },
          }}
          onClick={() => setRefresh(!refresh)}
        >
          <RefreshIcon />
        </IconButton>
      </Box>

      {/* Navigation Items */}
      {items.map((item) => renderSection(item, data[item]))}
    </Box>
  );
}
