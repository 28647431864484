import "../../Styles/requests.scss";
import AccordionBody from "../Util/AccordionBody";
import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import { BsEmojiSmile } from "react-icons/bs";
import WaveLoading from "../Util/WaveLoading";
import MyError from "../Util/MyError";
import AdminBody from "./AdminBody";
import CustomBarChart from "../Stats/CustomBarChart";
import GaugeChart from "react-gauge-chart";
import { MdAddTask } from "react-icons/md";
import { FiRefreshCcw } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function Summary(props) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [active, setActive] = useState("New Jobs");
  const [loading, setLoading] = useState(true);
  const [showing, setShowing] = useState(true);
  const [jobs, setJobs] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [arrow, setArrow] = useState("fa fa-angle-up");
  const [user, setUser] = useState("");
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  var jwt = require("jsonwebtoken");

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");
    if (token) {
      try {
        const decoded = jwt.decode(token);
        setUser(decoded.Name);
        if (decoded.Role !== "Admin" && decoded.Role !== "Super Admin") {
          navigate("/");
        }
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    fetch(`/api/requests/adminstats/top`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [refresh]);

  useEffect(() => {
    setJobs(null);
    if (active === "New Jobs") {
      setLoading(true);
      fetch(`/api/requests/paginated/Received/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setJobs(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (active === "Active Jobs") {
      setLoading(true);
      fetch(`/api/requests/paginated/Assigned/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setJobs(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      fetch(`/api/requests/paginated/Complete/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);

          setJobs(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [offset, active, refresh]);

  return (
    <div className="requests">
      <div className="welcome">
        <div>
          <h1>Welcome Back, {user}</h1>
          <p>
            Let's do our best today <BsEmojiSmile color="orange" />
          </p>
        </div>
        <FiRefreshCcw
          onClick={() => {
            setRefresh(!refresh);
          }}
          className="refresh"
        />
      </div>
      <div className="taskstats">
        <div className="left">
          <div className="bar">
            <div></div>
            <h2>Job Requests</h2>
          </div>
          <div className="outer">
            <div className="ll">
              <div className="section">
                <div className="single">
                  <MdAddTask className="ts" />
                  <div>
                    <h4>{data ? data?.AllTasks : 0}</h4>
                    <p>All Tasks</p>
                    <p>
                      Completed: <b>{data ? data?.Complete : 0}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="section">
                <div className="gauge">
                  <h3>Completion Rate</h3>
                  <GaugeChart
                    className="gg"
                    id="gauge-chart2"
                    nrOfLevels={3}
                    colors={["red", "orange", "green"]}
                    arcWidth={0.3}
                    percent={
                      data ? (data?.Complete / data?.AllTasks).toFixed(2) : 0
                    }
                    textColor="gray"
                  />
                </div>
              </div>
            </div>
            <div className="section">
              <h3>Monthly Performance</h3>
              <CustomBarChart
                aspect={1.8}
                data={data ? data.Bar : null}
                color="orange"
              />
            </div>
          </div>
        </div>
        <div className="right">
          <div className="bar">
            <div></div>
            <h2>Performance</h2>
          </div>
          <div className="outer">
            <div className="section">
              <div className="perf">
                <div className="hl">
                  <h4>{data ? data?.Overdue : 0}</h4>
                  <h5>Overdue</h5>
                </div>
                <h2>Task Due Date</h2>
                <div className="div1auto">
                  <div style={{ backgroundColor: "red" }}></div>
                  <p>1 Day:</p>
                  <h6>{data ? data?.Day1 : 0}</h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "orange" }}></div>
                  <p>2 Days:</p>
                  <h6>{data ? data?.Day2 : 0}</h6>
                </div>
                <div className="div1auto">
                  <div style={{ backgroundColor: "green" }}></div>
                  <p> 3 or More Days:</p>
                  <h6>{data ? data?.Day3 : 0}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
}
