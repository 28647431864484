import React, { useEffect, useRef, useState } from "react";
import "../../Styles/_header.scss";
import logo from "../../assets/images/logo.png";
import HeaderModal from "./modal_header";
import { useNavigate } from "react-router-dom";
function NavLink(props) {
  return (
    <>
      {props.active === props.txt ? (
        <a data-aos="zoom-in" className="active" href={props.url}>
          {props.txt}
        </a>
      ) : (
        <a data-aos="zoom-in" href={props.url}>
          {props.txt}
        </a>
      )}
    </>
  );
}

export default function Header1(props) {
  const [clicked, setClicked] = useState(false);
  const hd = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", changeCss, false);
  }, []);

  function changeCss() {
    // if (this.scrollY > 0) {
    //   hd.current.style.position = "fixed";
    // } else {
    //   hd.current.style.position = "relative";
    // }
  }

  return (
    <>
      <div ref={hd} className="header1">
        <div className="contacts">
          <div className="ccontainer">
            <div>
              <img
                onClick={() => {
                  navigate("/");
                }}
                src={logo}
                alt=""
              />
              <h1>Admin Portal</h1>
            </div>
            <a href="tel:+25425214688">
              <i className="fa ">&#xf095;</i> 0725 214 688
            </a>
            <a href="mailto:info@aropegroup.com">
              <i className="fa ">&#xf1fa;</i> victoria@aropegroup.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
