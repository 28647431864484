import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function ChangePassword({
  open,
  onClose,
  isAuthenticated,
  currentUser,
  setIsAuthenticated,
}) {
  const [isError, setIsError] = useState("");
  const [body, updateBody] = useState({
    Password: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const rfOldPassword = useRef();
  const rfNewPassword = useRef();
  const rfConfirmNewPassword = useRef();
  const navigate = useNavigate();

  const changePassword = () => {
    if (isAuthenticated) {
      const d = {
        Password: rfOldPassword.current.value,
        NewPassword: rfNewPassword.current.value,
        ConfirmNewPassword: rfConfirmNewPassword.current.value,
      };

      updateBody(d);
      setIsError("");

      if (!d.Password) return setIsError("Old password is required!");
      if (!d.NewPassword || d.NewPassword.length < 6)
        return setIsError("New Password must be at least 6 characters!");
      if (!validatePassword(d.NewPassword, d.ConfirmNewPassword))
        return setIsError("New Password and Confirm New Password do not match");

      setIsLoading(true);
      fetch(`/api/auth/${currentUser.UserID}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(d),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw new Error("Could not change password");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            setIsError(data.success);
            localStorage.clear();
            setIsAuthenticated(false);
            navigate("/");
          } else {
            setIsError(data.error);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError("Could not change password!");
        });
    } else {
      setIsError("You have been logged out! Please log in again.");
    }
  };

  const validatePassword = (newPassword, confirmNewPassword) => {
    return confirmNewPassword === newPassword;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Change Password for {currentUser.Name}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isError && (
          <Typography color="error" sx={{ mb: 2 }}>
            {isError}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            inputRef={rfOldPassword}
            label="Enter Old Password *"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            inputRef={rfNewPassword}
            label="Enter New Password *"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            inputRef={rfConfirmNewPassword}
            label="Confirm New Password *"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={changePassword}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
