import React, { useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Button, CircularProgress, Stack } from "@mui/material";
import { Download, Reply } from "@mui/icons-material";

const GenericPdfDownloader = ({
  rootElementId,
  downloadFileName,
  id,
  email,
  name,
  refresh,
  setRefresh,
}) => {
  const [loading, setLoading] = useState(false);

  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    const a4WidthInMM = 210; // A4 width in mm
    const a4HeightInMM = 297; // A4 height in mm

    html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Calculate the width and height of the image in pixels for A4 at 300 DPI
      const pdfWidth = a4WidthInMM;
      const pdfHeight = (canvas.height * a4WidthInMM) / canvas.width;

      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${downloadFileName}.pdf`);
    });
  };

  function sendData() {
    const input = document.getElementById(rootElementId);
    html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
      setLoading(true);
      const imgData = canvas.toDataURL("image/png");

      // Calculate the width and height of the image in pixels for A4 at 300 DPI
      const pdfWidth = 210;
      const pdfHeight = (canvas.height * 210) / canvas.width;

      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      const blb = pdf.output("blob");

      const formData = new FormData();
      formData.append("Report", blb, `${downloadFileName}.pdf`);
      formData.append("email", `${email}`);
      formData.append("name", `${name}`);

      fetch(`/api/uberreport/uploadreport/${id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setRefresh(!refresh);
        })
        .catch((e) => {
          setLoading(false);
        });
    });
  }

  return (
    <Stack mt={2} spacing={2}>
      <Button
        sx={{ textTransform: "capitalize" }}
        variant="contained"
        onClick={downloadPdfDocument}
        startIcon={<Download />}
      >
        Download
      </Button>
      <Button
        disabled={loading}
        variant="contained"
        onClick={sendData}
        startIcon={<Reply />}
      >
        {loading ? <CircularProgress /> : "Send"}
      </Button>
    </Stack>
  );
};

export default GenericPdfDownloader;
