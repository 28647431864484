import {
  faAddressBook,
  faArrowLeftLong,
  faAt,
  faDownload,
  faEnvelope,
  faPhone,
  faSave,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";

import "../../Styles/reporttemplate.scss";
import logo from "../../assets/images/logo.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import image from "../../assets/images/bg7.jpg";
import { useNavigate } from "react-router-dom";
import { ArrowBack, Download, Mail, Save } from "@mui/icons-material";

export default function ReportTemplate(props) {
  const [data, setData] = useState(null);
  const [payData, setPayData] = useState(null);
  const [checks, setChecks] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const id = window.location.pathname.split("/")[2];
  const month = window.location.pathname.split("/")[4];
  const year = window.location.pathname.split("/")[5];
  const [monthname, setMonthname] = useState(null);
  const [img, setImg] = useState(null);
  var jwt = require("jsonwebtoken");
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        setCurrentUser(decoded);
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    fetch(`/api/subjects/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
        setChecks(JSON.parse(data?.Checks));
        console.log(checks);
      })
      .catch((e) => {});
  }, [id]);

  useEffect(() => {
    if (data) {
      fetch(`/api/uploads/${data?.NationalID}`)
        .then((res) => {
          if (res.ok) return res.blob();
          else throw Error("");
        })
        .then((data) => {
          setImg(data);
        })
        .catch((e) => {});
    }
  }, [data]);

  const downloadPdfDocument = async (
    verticalStretchFactor,
    topPadding,
    bottomPadding
  ) => {
    const invoice = document.getElementById("invoice");
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const canvas = await html2canvas(invoice, { scale: 2 });
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    const aspectRatio = canvasWidth / canvasHeight;
    const pageHeight = pdfWidth / aspectRatio;

    // Calculate available content height with padding
    const availableHeight = pdfHeight - topPadding - bottomPadding;

    const pages = Math.ceil(
      canvasHeight / ((canvasWidth / pdfWidth) * availableHeight)
    );
    const sliceHeight = (canvasHeight * verticalStretchFactor) / pages; // Adjusted slice height

    for (let i = 0; i < pages; i++) {
      if (i > 0) {
        pdf.addPage();
      }

      const srcY = sliceHeight * i;
      const pageCanvas = document.createElement("canvas");
      pageCanvas.width = canvasWidth;
      pageCanvas.height = sliceHeight;
      const pageCtx = pageCanvas.getContext("2d");
      pageCtx.drawImage(
        canvas,
        0,
        srcY,
        canvasWidth,
        sliceHeight,
        0,
        0,
        canvasWidth,
        sliceHeight
      );

      const pageImgData = pageCanvas.toDataURL("image/png");
      pdf.addImage(
        pageImgData,
        "PNG",
        0,
        topPadding,
        pdfWidth,
        availableHeight
      );
    }

    pdf.save("invoice.pdf");
  };

  const sendPdfDocument = () => {
    const invoice = document.getElementById("invoice");

    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the aspect ratio
      const aspectRatio = canvas.width / canvas.height;

      // Calculate the image dimensions for the PDF
      let imgWidth = pdfWidth;
      let imgHeight = pdfWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = pdfHeight * aspectRatio;
      }

      // Center the image in the PDF
      const xOffset = (pdfWidth - imgWidth) / 2;
      const yOffset = 0; // Start from the top for the main content

      pdf.addImage(imgData, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      // Now handle the footer separately
      // Capture the footer separately
      const footer = document.querySelector(".invfooter");

      html2canvas(footer, { scale: 2 }).then((footerCanvas) => {
        const footerImgData = footerCanvas.toDataURL("image/png");

        // Calculate the dimensions of the footer image
        const footerAspectRatio = footerCanvas.width / footerCanvas.height;
        let footerImgWidth = pdfWidth;
        let footerImgHeight = pdfWidth / footerAspectRatio;

        if (footerImgHeight > pdfHeight) {
          footerImgHeight = pdfHeight;
          footerImgWidth = pdfHeight * footerAspectRatio;
        }

        // Add footer image at the bottom
        const footerYOffset = pdfHeight - footerImgHeight; // Bottom position

        pdf.addImage(
          footerImgData,
          "PNG",
          0,
          footerYOffset,
          footerImgWidth,
          footerImgHeight
        );
        const blb = pdf.output(`blob`);
        sendData(blb, `${data.Name}-${month}-${year}`);
      });
    });
  };

  function sendData(pdf, downloadFileName) {
    const formData = new FormData();
    formData.append("Payslip", pdf, `${downloadFileName}.pdf`);
    formData.append("Email", data?.Email);
    formData.append("Name", data?.Name);
    formData.append("Month", monthname ? monthname : month);
    formData.append("Year", year);

    fetch(`/api/payslip/sendemail/${data?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            setError(null);
          }, 1000);
        } else {
          setError(data.error);
          setTimeout(() => {
            setError(null);
          }, 1000);
        }
      })
      .catch((e) => {});
  }

  return (
    <div className="reportytemplate">
      <div className="payslip">
        <div className="top">
          <h2>{data?.Name} Report Template</h2>

          <button
            onClick={() => {
              navigate("/payroll");
            }}
          >
            <ArrowBack icon={faArrowLeftLong} />
            Back
          </button>
        </div>
        <div className="divsplit">
          <div className="inv_preview">
            <div id="invoice" className="invoice">
              <div className="bar">
                <div className="invtop">
                  <img src={logo} alt="" />
                  <br />
                  <p>P.O Box 20252 - 00100</p>
                  <p>Vedic House, 5th Floor, North Wing</p>
                  <p>Mama Ngina Street, Nairobi</p>
                  <p>Tel: +254 754 709 777, +254 726 709 777</p>
                  <p>Email: info@aropegroup.com</p>
                  <p>Website: www.aropegroup.com</p>
                </div>
                <img className="photo" src={img ? img : image} alt="" />
                <div className="div"></div>
              </div>
              <div className="invheader">
                <div className="left">
                  <div className="enclose">
                    <h4>Candidate's ID Number</h4>
                    <p>{data?.IDNo}</p>
                  </div>
                </div>
                <div className="right">
                  <div className="div1auto">
                    <h4>Date:</h4>
                    <p>{formatDate(new Date())}</p>
                  </div>
                  <div className="div1auto">
                    <h4>Ref No:</h4>
                    <p>A45/0033/21/05</p>
                  </div>
                </div>
              </div>
              <br />
              <div className="invheader">
                <div className="left">
                  <div className="enclose">
                    <h4>Phone Number</h4>
                    <p>{data?.Phone}</p>
                  </div>
                </div>
              </div>

              <div className="section">
                <div className="sect">
                  <h4>AROPE CANDIDATE REPORT</h4>
                  <div className="div3">
                    <div className="td">
                      <p>Surname</p>
                      <input
                        type="text"
                        placeholder="P"
                        value={
                          data?.Name.split(" ")[
                            data?.Name.split(" ").length - 1
                          ]
                        }
                      />
                    </div>
                    <div className="td">
                      <p>Middle Name</p>
                      <input
                        type="text"
                        placeholder="P"
                        value={
                          data?.Name.split(" ").length > 2
                            ? data?.Name.split(" ")[1]
                            : "N/A"
                        }
                      />
                    </div>
                    <div className="td">
                      <p>First Name</p>
                      <input
                        type="text"
                        placeholder="P"
                        value={data?.Name.split(" ")[0]}
                      />
                    </div>
                  </div>
                </div>

                <div className="sect">
                  <h4>CHECKS CONDUCTED</h4>
                  <div className="div21">
                    <h5 className="td">Type of Check</h5>
                    <h5 className="td">Comments/Findings</h5>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Identification Check</h5>
                    </div>
                    <div className="td">
                      <p>The National ID card is authentic.</p>
                      <div>
                        <p>
                          The ID details match the records in the IPRS database.
                        </p>
                        <input type="text" placeholder="P" />
                      </div>
                      <hr />
                      <div className="div2equal">
                        <div>
                          <div className="div1a">
                            <p>County:</p>
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>District: </p>{" "}
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>Division: </p>{" "}
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>Location: </p>{" "}
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>Sub Location: </p>{" "}
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>Village: </p>
                          </div>
                        </div>
                        <div>
                          <div className="div1a">
                            <p>ID Sn No.:</p>
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>DoB: </p>{" "}
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>Gender: </p>{" "}
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>Ethnic Group: </p>{" "}
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>Clan: </p>{" "}
                            <input type="text" name="" placeholder="P" />
                          </div>
                          <div className="div1a">
                            <p>Family: </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Criminal Check</h5>
                    </div>
                    <div className="td">
                      <p>
                        Nil criminal records as per the Criminal Records Office
                        database.{" "}
                      </p>
                      <input type="text" placeholder="P" />
                    </div>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Credit Check</h5>
                    </div>
                    <div className="td">
                      <p>
                        Has credit records as per the Credit Reference Bureau.{" "}
                      </p>
                      <input type="text" placeholder="P" />
                    </div>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Academic Qualification Check</h5>
                    </div>
                    <div className="td">
                      <p>Confirmed that </p>
                      <input type="text" placeholder="P" />
                    </div>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Reference Checks</h5>
                    </div>
                    <div className="td">
                      <p>Find attached Reference Reports from: </p>
                      <input type="text" placeholder="P" />
                    </div>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Previous Employment Check</h5>
                    </div>
                    <div className="td">
                      <p>
                        Confirmed that {data?.Name} is a former employee of:{" "}
                      </p>
                      <input type="text" placeholder="P" />
                    </div>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Directorship Search</h5>
                    </div>
                    <div className="td">
                      <p>
                        Directorship Search No ownership of a company as per the
                        database of KRA iTax Returns.
                      </p>
                      <input type="text" placeholder="P" />
                    </div>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Career Gaps Check</h5>
                    </div>
                    <div className="td">
                      <p>No significant gaps noted. </p>
                      <input type="text" placeholder="P" />
                    </div>
                  </div>
                  <div className="div21">
                    <div className="td">
                      <h5>Documents Verification</h5>
                    </div>
                    <div className="td">
                      <p>
                        NHIF membership confirmed authentic as per the NHIF
                        database. Member No:{" "}
                      </p>
                      <input type="text" placeholder="P" />
                      <p>Last Contribution made on</p>
                      <input type="text" placeholder="P" />
                      <hr />
                      <p>
                        KRA pin certificate is genuine and valid as per the KRA
                        database.
                      </p>
                      <input type="text" placeholder="P" />
                    </div>
                  </div>
                </div>

                <div className="sect">
                  <h4>Additional Information</h4>
                  <div className="td">
                    <p>
                      {data?.Name} last NHIF record shows the employer as P.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} dependent(s) are listed below as per the NHIF
                      database;
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>We were unable to get his/her NHIF Data.</p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      We noticed a nnnnn months career gap between nnnnn in{" "}
                      {data?.Name}'s CV.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} has never applied for the Police Clearance
                      Certificate through eCitizen.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} had successfully applied for the Police
                      Clearance Certificate on Day Month Year (Fingerprints to
                      be taken at location), but the analysis and processing of
                      {data?.Name}'s fingerprints at the Directorate of Criminal
                      Investigation Headquarters is still pending (queued), and
                      there is no record of a recently generated Police
                      Clearance Certificate that belongs to {data?.Name}.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} has an active KRA Pin but has not updated
                      {data?.Name}'s iPage.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} has an updated iPage but {data?.Name} KRA pin
                      has been suspended.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} registered Rent Income (Effective from nnnnn)
                      as one of {data?.Name}'s income taxes.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} had applied for a new National ID card of
                      serial No. P (issued on P). {data?.Name} should use the
                      most recent card.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} has been an employee of the following
                      companies (nnnn) as per the Credit Report;
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      {data?.Name} has nnnnn credit application(s), with nnnnn
                      reported fraud, nnnnn bounced cheques, and the total
                      outstanding loan balance in P accounts is KSHs. nnnnn as
                      per the attached Credit Report.
                    </p>
                    <input type="text" placeholder="P" />
                  </div>
                </div>

                <div className="sect">
                  <h4>Note - When examining candidates credit report</h4>
                  <div className="td">
                    <p>
                      The index ranges from M1 to M9, where M1 indicates
                      negligible delays in payments, while M9 indicates the
                      candidate delays on average over 3 times the agreed
                      payment terms.
                    </p>
                    <p>{data?.Name}'s Payment Performance Index (PPI) is P</p>
                    <input type="text" placeholder="P" />
                  </div>
                  <div className="td">
                    <p>
                      Metro-Score ranges from 200 to 900, with higher scores
                      indicating better credit quality than lower scores. A
                      score of less than 400 indicates that the candidate is in
                      default.
                    </p>
                    <p>{data?.Name}'s Metro-Score is P.</p>
                    <input type="text" placeholder="P" />
                  </div>
                </div>
              </div>
              <div className="close">
                <h6>
                  Disclaimer: It is the responsibility of the employer to ensure
                  that policies are followed. In case of any criminal act from
                  any of our vetted candidates, Arope is highly willing to offer
                  consultation support at an agreed fee. Arope will not be
                  liable for any criminal act after a candidate has been vetted
                  and screened.
                </h6>
                <div className="mark">
                  <h5>Overall Status of the Report With Color Code</h5>
                  <div className="result">Clear</div>
                </div>
                <div className="key">
                  <h5>Key</h5>
                  <div className="div12">
                    <div
                      style={{ backgroundColor: "green", borderColor: "green" }}
                    ></div>
                    <p>Clear</p>
                  </div>
                  <div className="div12">
                    <div
                      style={{
                        backgroundColor: "orange",
                        borderColor: "orange",
                      }}
                    ></div>
                    <p>Consider</p>
                  </div>
                  <div className="div12">
                    <div
                      style={{ backgroundColor: "red", borderColor: "red" }}
                    ></div>
                    <p>Adverse</p>
                  </div>
                  <div className="div12">
                    <div
                      style={{ backgroundColor: "blue", borderColor: "blue" }}
                    ></div>
                    <p>Partially Verified</p>
                  </div>
                </div>
                <h2>
                  This is a system generated report. Generated by{" "}
                  <b>{currentUser?.Name}</b>. On behalf of Arope I confirm that
                  the information given is correct and accurate as of the date
                  below
                </h2>
              </div>
            </div>
            <div className="invfooter">
              <h5>Your world class partner</h5>
            </div>
          </div>
          <div className="iright_panel">
            <div className="links">
              <button
                onClick={() => {
                  sendPdfDocument();
                }}
              >
                <Mail className="ic" icon={faEnvelope} />
                {payData && payData.Payslip ? "Send Again" : "Send Report"}
              </button>
              {error && <h6>{error}</h6>}
              <button
                onClick={() => {
                  downloadPdfDocument(1.3, 10, 10);
                }}
              >
                <Download className="ic" icon={faDownload} /> Download Report
              </button>
              <button onClick={() => {}}>
                <Save className="ic" icon={faSave} /> Save Draft
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
