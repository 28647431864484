import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Navbar from "./Navbar";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import Stats from "../components/Stats/Stats";
import PortalUsers from "../components/PortalUsers/PortalUsers";
import Invoicing from "./Invoicing/Invoicing";
import NewInvoice from "./Invoicing/NewInvoice";
import PreviewInvoice from "./Invoicing/PreviewInvoice";
import Customers from "../components/Customers/Customers";
import Requests from "../components/Requests/Requests";
import NewDocument from "../components/Requests/NewDocument";
import SingleTask from "../components/Tasks/SingleTask";
import Tasks from "../components/Tasks/Tasks";
import UberTask from "../components/Uber/UberTask";
import RiderTask from "../components/Rider/RiderTask";
import Payroll from "../components/Payroll/Payroll";
import Payslip from "../components/Payroll/Payslip";
import Employees from "../components/Payroll/Employees";
import UberHome from "../components/Uber/UberHome";
import Main from "../components/Folders/Main";
import Data from "../components/Data/Data";
import ReportTemplate from "../components/Tasks/ReportTemplate";
import Rider from "../components/Rider/Rider";
import Settings from "./Settings/Settings";
import ReqeustPopup from "./Requests/RequestPopup";
import Summary from "./Requests/Summary";
import UberList from "./Uber/UberList";
import CreateUber from "./Uber/CreateTask";
import Invoices from "./Invoicing/Invoices";

function PageRoutes() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar user={user} setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 8 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Routes>
            {/* Home */}
            <Route path="/" element={<Stats user={user} />} />

            {/* Customers */}
            <Route path="/customers" element={<Customers user={user} />} />

            {/* Invoices */}
            <Route path="/invoices" element={<Invoices user={user} />} />
            <Route path="/invoices/List" element={<Invoicing user={user} />} />
            <Route path="/invoices/new" element={<NewInvoice user={user} />} />
            <Route
              path="/invoices/preview/*"
              element={<PreviewInvoice user={user} />}
            />

            {/* Documents */}
            <Route path="/documents" element={<Main user={user} />} />

            {/* Users */}
            <Route path="/users" element={<PortalUsers user={user} />} />

            {/* Requests */}
            <Route path="/requests/summary" element={<Summary user={user} />} />
            <Route path="/requests/List" element={<Requests user={user} />} />
            <Route path="/requests/New" element={<NewDocument user={user} />} />
            <Route path="/requests/admin/*" element={<ReqeustPopup />} />
            {/* Tasks */}
            <Route path="/task" element={<Tasks user={user} />} />
            <Route path="/singletask/*" element={<SingleTask user={user} />} />
            <Route path="/createreport/*" element={<ReportTemplate />} />

            {/* Uber */}
            <Route path="/ubertask/*" element={<UberTask user={user} />} />
            <Route path="/uber" element={<UberHome />} />
            <Route path="/uber/List" element={<UberList user={user} />} />
            <Route path="/uber/New" element={<CreateUber user={user} />} />

            {/* Payroll */}
            <Route path="/payroll" element={<Payroll user={user} />} />
            <Route path="/payroll/payslip" element={<Payslip user={user} />} />
            <Route
              path="/payroll/employees"
              element={<Employees user={user} />}
            />

            {/* Riders */}
            <Route path="/rider" element={<Rider user={user} />} />
            <Route path="/ridertask/*" element={<RiderTask user={user} />} />

            {/* Settings */}
            <Route path="/settings" element={<Settings user={user} />} />
          </Routes>
        )}
      </Box>
    </Box>
  );
}

export default PageRoutes;
