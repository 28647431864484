import "../../Styles/employees.scss";

import {
  faArrowLeftLong,
  faSearch,
  faTimes,
  faTrash,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../Util/Input";
import Button from "../Util/Button";
import Select from "../Util/Select";
import { useEffect, useRef, useState } from "react";
import WaveLoading from "../Util/WaveLoading";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ArrowBack, Close, Delete, Edit, Search } from "@mui/icons-material";

export default function Employees(params) {
  const [showing, setShowing] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const [payslip, setPayslip] = useState([]);
  const [month, setMonth] = useState("January");
  const [year, setYear] = useState("2024");
  const { getMonths } = require("../../assets/data/data");
  const navigate = useNavigate();

  useEffect(() => {
    let today = new Date().getMonth();
    setYear(new Date().getFullYear());
    today = today.toString().length == 1 ? `0${today + 1}` : today + 1;

    const i = getMonths
      .map((e) => {
        return e.code;
      })
      .indexOf(today);
    setMonth(getMonths[i].month);
  }, [getMonths, showing]);

  useEffect(() => {
    setData(null);
    fetch(`/api/employees`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [showing, refresh]);

  function searchEmployees(q) {
    if (payslip) {
      setData(null);

      fetch(`/api/employees/searchall/${q}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setData(data);
        })
        .catch((e) => {});
    }
  }

  return (
    <div className="employees">
      <div className="leading">
        <h1>Employees List</h1>
        <button
          onClick={() => {
            navigate("/payroll");
          }}
        >
          <ArrowBack icon={faArrowLeftLong} /> Back
        </button>
      </div>
      <div className="container">
        <div className="intro">
          <div className="div1auto">
            <div className="search">
              <input
                onChange={(e) => {
                  if (e.target.value != "") {
                    searchEmployees(e.target.value);
                  } else {
                    setRefresh(!refresh);
                  }
                }}
                type="text"
                placeholder="Search for employee"
              />
              <Search className="ic" icon={faSearch} />
            </div>
            <button
              onClick={() => {
                setShowing(true);
              }}
            >
              New Employee
            </button>
          </div>
        </div>
        <div className="list">
          {data &&
            data.map((item, i) => {
              return (
                <EmpItem
                  key={i}
                  index={i}
                  payslip={payslip}
                  item={item}
                  month={month}
                  getMonths={getMonths}
                  year={year}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              );
            })}
        </div>
      </div>
      {showing && <NewEmployee setShowing={setShowing} />}
    </div>
  );
}

const EmpItem = (props) => {
  const [showing, setShowing] = useState(false);

  return (
    <>
      <div className="row">
        <div className="div1auto">
          <div
            style={{
              backgroundColor: props.item.Status ? "antiquewhite" : "red",
            }}
            className="no"
          >
            {props.item.EmployeeNo}
          </div>
          <div>
            <h3>{props.item.Name}</h3>
            <p>
              {props.item.Phone} - {props.item.Email}
            </p>
          </div>
          {props.item.Gender == "Female" ? (
            <BsGenderFemale className="gender" size={24} color={"#b31dc7"} />
          ) : (
            <BsGenderMale className="gender" size={24} color="blue" />
          )}
        </div>
        <hr />
        <div className="designation">{props.item.Designation}</div>

        <div>
          <p>
            <b>Gross Pay: </b>
            {props.item.Gross.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p>
            <b>Statutory Deductions: </b>{" "}
            {calculateStatutory(props.item).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p>
            <b>Other Deductions: </b>{" "}
            {calculateDeductions(props.item).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          <p>
            <b>Net Pay: </b>
            {(
              props.item.Gross -
              calculateStatutory(props.item) -
              calculateDeductions(props.item)
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
        <Edit
          onClick={() => {
            setShowing(true);
          }}
          className="edit"
          icon={faUserEdit}
        />
        <Delete className="delete" icon={faTrash} />
      </div>
      {showing && (
        <UpdateEmployee
          item={props.item}
          setShowing={setShowing}
          setRefresh={props.setRefresh}
          refresh={props.refresh}
        />
      )}
    </>
  );
};

const UpdateEmployee = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [spaye, setSpaye] = useState(props.item.PAYE);
  const [snssf, setSnssf] = useState(props.item.NSSF);
  const [snhif, setSnhif] = useState(props.item.NHIF);
  const [shl, setShl] = useState(props.item.HousingLevy);
  const nm = useRef();
  const eno = useRef();
  const gd = useRef();
  const ds = useRef();
  const em = useRef();
  const ph = useRef();
  const gp = useRef();
  const doe = useRef();
  const paye = useRef();
  const nhif = useRef();
  const nssf = useRef();
  const pension = useRef();
  const hl = useRef();
  const hlb = useRef();
  const ln = useRef();
  const act = useRef();

  function createEmployee() {
    const body = {
      Name: nm.current.value,
      EmployeeNo: eno.current.value,
      Gender: gd.current.value,
      Designation: ds.current.value,
      Email: em.current.value,
      Phone: ph.current.value,
      Gross: gp.current.value,
      DOE: doe.current.value,
      PAYE: spaye,
      NHIF: snhif,
      NSSF: snssf,
      Pension: pension.current.value,
      HELB: hlb.current.value,
      Loan: ln.current.value,
      HousingLevy: shl,
      Status: act.current.value,
    };

    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null || item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch(`/api/employees/${props.item.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          props.setRefresh(!props.refresh);
          setTimeout(() => {
            props.setShowing(false);
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  }

  return (
    <div className="popup">
      <div className="wrap">
        <div className="bar">
          <h4>New Employee</h4>
          <Close
            onClick={() => {
              props.setShowing(false);
            }}
            icon={faTimes}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          action=""
        >
          <h4>Employee Details</h4>
          <div className="div2equal">
            <Input ref={nm} label="Name" value={props.item.Name} />
            <Input
              ref={eno}
              label="Employee No"
              value={props.item.EmployeeNo}
            />
          </div>{" "}
          <div className="div2equal">
            <Select
              ref={gd}
              label="Gender"
              data={["Male", "Female"]}
              value={props.item.Gender}
            />
            <Input
              ref={ds}
              label="Designation"
              value={props.item.Designation}
            />
          </div>{" "}
          <div className="div2equal">
            <Input
              ref={em}
              label="Email"
              type="email"
              value={props.item.Email}
            />
            <Input
              ref={ph}
              label="Phone"
              type="phone"
              value={props.item.Phone}
            />
          </div>{" "}
          <div className="div2equal">
            <Input
              ref={gp}
              label="Gross Pay"
              type="number"
              value={props.item.Gross.toString()}
            />
            <Input
              ref={doe}
              label="Date of Employment"
              value={props.item.DOE}
              type="date"
            />
          </div>
          <h4>Deductions</h4>
          <div className="div3equal">
            <Input
              ref={hlb}
              label="HELB"
              type="number"
              value={props.item.HELB.toString()}
            />
            <Input
              ref={pension}
              label="Pension"
              value={props.item.Pension.toString()}
              type="number"
            />
            <Input
              ref={ln}
              label="Loan(s)"
              value={props.item.Loan.toString()}
              type="number"
            />
          </div>
          <Select
            ref={act}
            label="Active"
            data={["true", "false"]}
            value={props.item.Status.toString()}
          />
          <div className="checkbox">
            <input
              ref={paye}
              type="checkbox"
              name=""
              id=""
              onChange={(e) => {
                setSpaye(e.target.checked);
              }}
              checked={spaye}
            />
            <p>Pay As You Earn (PAYE) deduction?</p>
          </div>
          <div className="checkbox">
            <input
              ref={nhif}
              type="checkbox"
              name=""
              id=""
              onChange={(e) => {
                setSnhif(e.target.checked);
              }}
              checked={snhif}
            />
            <p>NHIF deduction?</p>
          </div>
          <div className="checkbox">
            <input
              ref={nssf}
              type="checkbox"
              name=""
              id=""
              onChange={(e) => {
                setSnssf(e.target.checked);
              }}
              checked={snssf}
            />
            <p>NSSF deduction?</p>
          </div>
          <div className="checkbox">
            <input
              ref={hl}
              type="checkbox"
              name=""
              id=""
              onChange={(e) => {
                setShl(e.target.checked);
              }}
              checked={shl}
            />
            <p>Housing levy deduction?</p>
          </div>
          <h6>{error}</h6>
          <Button
            handleClick={() => {
              createEmployee();
            }}
            value="Submit"
          />
        </form>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
};

const NewEmployee = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const nm = useRef();
  const eno = useRef();
  const gd = useRef();
  const ds = useRef();
  const em = useRef();
  const ph = useRef();
  const gp = useRef();
  const doe = useRef();
  const paye = useRef();
  const nhif = useRef();
  const nssf = useRef();
  const pension = useRef();
  const hl = useRef();
  const hlb = useRef();
  const ln = useRef();

  const NextOfKinName = useRef();
  const NextOfKinRelationship = useRef();
  const NextOfKinContact = useRef();
  const DOB = useRef();
  const MaritalStatus = useRef();
  const IDNo = useRef();
  const College = useRef();
  const Course = useRef();
  const NSSFNumber = useRef();
  const NHIFNumber = useRef();

  function createEmployee() {
    const body = {
      Name: nm.current.value,
      EmployeeNo: eno.current.value,
      Gender: gd.current.value,
      Designation: ds.current.value,
      Email: em.current.value,
      Phone: ph.current.value,
      Gross: gp.current.value,
      DOE: doe.current.value,
      PAYE: paye.current.value,
      NHIF: nhif.current.value,
      NSSF: nssf.current.value,
      Pension: pension.current.value,
      HELB: hlb.current.value,
      Loan: ln.current.value,
      HousingLevy: hl.current.value,
      NextOfKinName: NextOfKinName.current.value,
      NextOfKinRelationship: NextOfKinRelationship.current.value,
      NextOfKinContact: NextOfKinContact.current.value,
      DOB: DOB.current.value,
      MaritalStatus: MaritalStatus.current.value,
      IDNo: IDNo.current.value,
      College: College.current.value,
      Course: Course.current.value,
      NSSFNumber: NSSFNumber.current.value,
      NHIFNumber: NHIFNumber.current.value,
    };

    const chck = Object.values(body);
    let valid = true;
    chck.map((item) => {
      if (item === null || item === "") {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/employees/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setShowing(false);
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  }

  return (
    <div className="popup">
      <div className="wrap">
        <div className="bar">
          <h4>New Employee</h4>
          <Close
            onClick={() => {
              props.setShowing(false);
            }}
            icon={faTimes}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          action=""
        >
          <h4>Personal Details</h4>
          <div className="div2equal">
            <Input ref={nm} label="Name" />
            <Select ref={gd} label="Gender" data={["Male", "Female"]} />
          </div>{" "}
          <div className="div2equal">
            <Input ref={em} label="Email" type="email" />
            <Input ref={ph} label="Phone" type="phone" />
          </div>{" "}
          <div className="div3equal">
            <Input
              ref={DOB}
              label="Date of Birth"
              type="date"
              value={new Date().toISOString().split("T")[0]}
            />
            <Input ref={IDNo} label="National ID No." type="number" />
            <Select
              ref={MaritalStatus}
              label="Marital Status"
              data={[
                "Single",
                "Engaged",
                "Married",
                "Widow",
                "Widower",
                "Divorced",
              ]}
            />
          </div>{" "}
          <div className="div3equal">
            <Input ref={NextOfKinName} label="Next of Kin" />
            <Select
              ref={NextOfKinRelationship}
              data={[
                "Mother",
                "Father",
                "Spouse",
                "Sibling",
                "Guardian",
                "Relative",
                "Other",
              ]}
              label="Relationship"
            />
            <Input ref={NextOfKinContact} label="Contact" type="number" />
          </div>
          <h4>Level of Education</h4>
          <div className="div2equal">
            <Input ref={College} label="School/College" type="text" />
            <Input ref={Course} label="Degree/Certificate" type="text" />
          </div>
          <h4>Employment Details</h4>
          <div className="div3equal">
            <Input ref={eno} label="Employee No" />
            <Input ref={ds} label="Designation" />
            <Input
              ref={doe}
              label="Date of Employment"
              value={new Date().toISOString().split("T")[0]}
              type="date"
            />
          </div>
          <div className="div2equal">
            <Input ref={NHIFNumber} label="NHIF Number" type="text" />
            <Input ref={NSSFNumber} label="NSSF Number" type="text" />
          </div>
          <h4>Payment Details</h4>
          <Input ref={gp} label="Gross Pay" type="number" />
          <div className="div3equal">
            <Input ref={hlb} label="HELB" value={0} type="number" />
            <Input ref={pension} label="Pension" value={0} type="number" />
            <Input ref={ln} label="Loan(s)" value={0} type="number" />
          </div>
          <div className="checkbox">
            <input ref={paye} type="checkbox" name="" id="" />
            <p>Pay As You Earn (PAYE) deduction?</p>
          </div>
          <div className="checkbox">
            <input ref={nhif} type="checkbox" name="" id="" />
            <p>NHIF deduction?</p>
          </div>
          <div className="checkbox">
            <input ref={nssf} type="checkbox" name="" id="" />
            <p>NSSF deduction?</p>
          </div>
          <div className="checkbox">
            <input ref={hl} type="checkbox" name="" id="" />
            <p>Housing levy deduction?</p>
          </div>
          <h6>{error}</h6>
          <Button
            handleClick={() => {
              createEmployee();
            }}
            value="Submit"
          />
        </form>
      </div>
      {loading && <WaveLoading />}
    </div>
  );
};

function calculateStatutory(item) {
  let d = 0;

  if (item.PAYE) {
    d += item.Gross * 0.16;
  }
  if (item.NHIF) {
    d += 1500;
  }
  if (item.NSSF) {
    d += 2160;
  }
  if (item.HousingLevy) {
    d += item.Gross * 0.0275;
  }
  return d;
}

function calculateDeductions(item) {
  return item.HELB + item.Loan + item.Pension;
}

function getLastDateOfPreviousMonth(m, y) {
  // Get the current date
  let currentDate = new Date(`${y}-${m}-01`);

  // Set the date to the first day of the current month
  currentDate.setDate(1);

  // Subtract one day to get the last day of the previous month
  currentDate.setDate(currentDate.getDate() - 1);

  // Format the date to yyyy-mm-dd
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  let day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
