import * as React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Chip,
  IconButton,
  useMediaQuery,
  useTheme,
  Container,
  TableContainer,
  Paper,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

export default function UberData(props) {
  const [data, setData] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [changed, setChanged] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const types = ["New Driver", "Driver Renewal", "New Rider", "Rider Renewal"];
  const [type, setType] = React.useState(types[0]);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // mobile or tablet

  React.useEffect(() => {
    setLoading(true);
    setData(null);
    fetch(`/api/uberreport/paginated/${type}/${(currentPage - 1) * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          document.cookie =
            "nimda_arope=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          navigate("/");
        }
        if (!res.ok) {
          throw new Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [changed, currentPage, type]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleSearchChange = (event) => {
    if (event.target.value !== "") {
      setLoading(true);
      fetch(`/api/uberreport/search/${event.target.value}?category=${type}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 401) {
            document.cookie =
              "nimda_arope=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            navigate("/");
          }
          if (!res.ok) {
            throw new Error("Could not fetch data!!!");
          }
          return res.json();
        })
        .then((data) => {
          setData(data);
        })
        .catch((err) => {
          console.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setData(null);
      setChanged(!changed);
    }
  };

  const getResultChipColor = (result) => {
    switch (result) {
      case "Pass":
        return "success";
      case "Fail":
        return "error";
      default:
        return "default";
    }
  };

  const handleCategoryChange = (selectedType) => {
    setType(selectedType);
    setChanged(!changed); // Trigger a re-fetch whenever the type changes
  };

  return (
    <Box marginTop={1}>
      <Stack spacing={3}>
        <ButtonGroup
          variant="outlined"
          aria-label="category selection"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            "& .MuiButton-root": {
              textTransform: "none", // Disable all caps for buttons
            },
          }}
        >
          {types.map((btnType) => (
            <Button
              key={btnType}
              onClick={() => handleCategoryChange(btnType)}
              variant={btnType === type ? "contained" : "outlined"}
            >
              {btnType}
            </Button>
          ))}
        </ButtonGroup>

        <Card
          style={{
            borderRadius: "16px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            padding: "16px",
          }}
        >
          <Box display="flex" alignItems="center" mb={2} flexWrap="wrap">
            <SearchIcon />
            <InputBase
              placeholder="Search by id or name or phone..."
              onChange={handleSearchChange}
              style={{ marginLeft: 8, flex: 1, minWidth: 150 }}
              sx={{ width: isMobile ? "100%" : "auto" }}
            />
          </Box>
          <Divider sx={{ my: "8px" }} />
          {loading ? (
            <Box
              sx={{ height: "70vh", display: "grid", placeContent: "center" }}
            >
              <CircularProgress />
            </Box>
          ) : isMobile && data ? (
            // Render Cards for Mobile/Tablet View
            <Stack spacing={2}>
              {data &&
                data?.data?.map((user, index) => (
                  <Card
                    key={user.ID}
                    sx={{
                      padding: 2,
                      borderRadius: "16px",
                      boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
                      position: "relative",
                    }}
                  >
                    <Chip
                      sx={{ position: "absolute", top: "10px", right: "10px" }}
                      label={index + 1 + (currentPage - 1) * 12}
                    />
                    <Chip label={user.SerialNo} />
                    <Typography variant="h6">{`${user.FirstName} ${user.MiddleName} ${user.Surname}`}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Phone: {user.Phone}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      National ID: {user.NationalID}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Category: {user.Category}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      User: {user.User}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Date: {user.Date}
                    </Typography>
                    <Divider sx={{ my: "8px" }} />
                    <Chip
                      sx={{ width: "100%" }}
                      label={user.Result}
                      color={getResultChipColor(user.Result)}
                    />
                  </Card>
                ))}
            </Stack>
          ) : (
            // Render Table for Desktop View
            <TableContainer
              sx={{ maxWidth: "85vw", width: "100%", overflowX: "auto" }}
              component={Paper}
            >
              <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>SNo</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data?.data?.map((user, index) => (
                      <TableRow key={user.ID}>
                        <TableCell padding="checkbox">
                          {index + 1 + (currentPage - 1) * 12}
                        </TableCell>
                        <TableCell>
                          <Chip label={user.SerialNo} />
                        </TableCell>
                        <TableCell>{`${user.FirstName} ${user.MiddleName} ${user.Surname}`}</TableCell>
                        <TableCell>{user.Phone}</TableCell>
                        <TableCell>{user.NationalID}</TableCell>
                        <TableCell>
                          <Chip
                            sx={{ fontStyle: "italic", fontSize: "x-small" }}
                            label={user.Category}
                            color="warning"
                          />
                        </TableCell>
                        <TableCell
                          sx={{ fontStyle: "italic", fontSize: "small" }}
                        >
                          {user.User}
                        </TableCell>
                        <TableCell>{user.Date}</TableCell>
                        <TableCell>
                          <Chip
                            label={user.Result}
                            color={getResultChipColor(user.Result)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Divider />
          <TablePagination
            component="div"
            count={data != null ? data.total : 0}
            page={currentPage - 1}
            onPageChange={handlePageChange}
            rowsPerPage={12}
            rowsPerPageOptions={[12]}
          />
        </Card>
      </Stack>
    </Box>
  );
}
