import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "./App.scss";
import { useEffect } from "react";
// importing pages
import NotFound from "./Pages/404";
import AOS from "aos";
import Landing from "./Pages/Landing";
import UberVerification from "./Pages/UberVerification";
import PageRoutes from "./components/PageRoutes";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/verify/*" element={<UberVerification />} />
        <Route path="/login" element={<Landing />} />


        <Route path="/*" element={<PageRoutes />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
