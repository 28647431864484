import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Chip,
  Grid2,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  Box,
  TableContainer,
  Card,
} from "@mui/material";
import {
  Search as SearchIcon,
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "../../Styles/users.scss";
import Loading from "../Util/Loading";
import SelectedUser from "./SelectedUser";

export default function Customers({ user }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openNewCustomerDialog, setOpenNewCustomerDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchCustomers();
  }, [offset, pageSize]);

  const fetchCustomers = () => {
    setLoading(true);
    fetch(`/api/mobile/paginated/${offset}?limit=${pageSize}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.ok && res.json())
      .then((data) => {
        setLoading(false);
        setData(data.data);
        setTotalCount(data.total);
      })
      .catch(() => setLoading(false));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setOffset(0);
    if (value) {
      setLoading(true);
      fetch(
        `/api/mobile/quicksearch/${value}?offset=${offset}&limit=${pageSize}`,
        {
          method: "get",
          credentials: "include",
        }
      )
        .then((res) => res.ok && res.json())
        .then((data) => {
          setLoading(false);
          setData(data.data);
          setTotalCount(data.total);
        })
        .catch(() => setLoading(false));
    } else {
      fetchCustomers();
    }
  };

  const columns = [
    {
      field: "No",
      headerName: "No",
      renderCell: (params) => {
        const rowIndex = data?.findIndex(
          (row) => row.UserID === params.row.UserID
        );
        return <Chip label={rowIndex + 1 + offset} />;
      },
    },
    {
      field: "Organisation",
      headerName: "Type",
      flex: 0.5,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Individual" ? "primary" : "secondary"}
        />
      ),
    },
    { field: "Name", headerName: "Name", flex: 1 },
    { field: "Email", headerName: "Email", flex: 1 },
    { field: "Phone", headerName: "Phone", flex: 0.8 },
    {
      field: "Status",
      headerName: "Status",
      renderCell: (params) => (
        <Chip
          label={params.value ? "Active" : "Inactive"}
          color={params.value ? "success" : "error"}
        />
      ),
    },
  ];

  const handleRowClick = (params) => {
    setSelectedUser(params.row);
    setOpenDialog(true);
    setEditMode(false);
  };

  const handlePageChange = (newPage) => {
    setOffset(newPage * pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setOffset(0);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveEdit = (updatedUser) => {
    // Implement save logic here
    setSelectedUser(updatedUser);
    setEditMode(false);
    fetchCustomers(); // Refresh the list after editing
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditMode(false);
  };

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          boxShadow: "0px 4px 8px #60606040",
          borderRadius: 3,
        }}
        component={Card}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            mb: 2,
          }}
        >
          <Typography variant="h5" flexGrow={1}>
            Customers
          </Typography>

          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenNewCustomerDialog(true)}
          >
            New Customer
          </Button>
        </Box>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={pageSize}
          rowCount={totalCount}
          loading={loading}
          paginationMode="server"
          page={offset / pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[12, 25, 50, 100]}
          onRowClick={handleRowClick}
          getRowId={(row) => row.UserID}
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          User Details
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedUser && (
            <>
              {editMode ? (
                <EditUserForm
                  user={selectedUser}
                  onSave={handleSaveEdit}
                  onCancel={() => setEditMode(false)}
                />
              ) : (
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">
                            Organization:
                          </Typography>
                        </TableCell>
                        <TableCell>{selectedUser.Organisation}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">Name:</Typography>
                        </TableCell>
                        <TableCell>{selectedUser.Name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">Email:</Typography>
                        </TableCell>
                        <TableCell>{selectedUser.Email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">Phone:</Typography>
                        </TableCell>
                        <TableCell>{selectedUser.Phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">Status:</Typography>
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={selectedUser.Status ? "Active" : "Disabled"}
                            color={selectedUser.Status ? "success" : "error"}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">
                            Date Created:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {new Date(selectedUser.createdAt).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">
                            Date Updated:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {new Date(selectedUser.updatedAt).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {user.Role === "Super Admin" && !editMode && (
            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {openNewCustomerDialog && (
        <NewCustomerDialog
          open={openNewCustomerDialog}
          onClose={() => setOpenNewCustomerDialog(false)}
          onSuccess={fetchCustomers}
        />
      )}
    </Box>
  );
}

const NewCustomerDialog = ({ open, onClose, onSuccess }) => {
  const [newCustomer, setNewCustomer] = useState({
    Name: "",
    Phone: "",
    Email: "",
    Organisation: "Individual",
    Password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setNewCustomer({ ...newCustomer, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    // Basic validation
    if (
      !newCustomer.Name ||
      !newCustomer.Phone ||
      !newCustomer.Email ||
      !newCustomer.Password
    ) {
      setError("All fields are required");
      return;
    }

    fetch("/api/mobile/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newCustomer),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          onSuccess();
          onClose();
        }
      })
      .catch((err) => {
        setError("An error occurred. Please try again.");
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        New Customer
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          margin="normal"
          label="Name"
          name="Name"
          value={newCustomer.Name}
          onChange={handleChange}
        />
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextField
              fullWidth
              margin="normal"
              label="Phone"
              name="Phone"
              value={newCustomer.Phone}
              onChange={handleChange}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="Email"
              type="email"
              value={newCustomer.Email}
              onChange={handleChange}
            />
          </Grid2>
        </Grid2>
        <TextField
          fullWidth
          margin="normal"
          label="Organisation"
          name="Organisation"
          select
          SelectProps={{
            native: true,
          }}
          value={newCustomer.Organisation}
          onChange={handleChange}
        >
          <option value="Individual">Individual</option>
          <option value="Organization">Organization</option>
        </TextField>
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          name="Password"
          type="password"
          value={newCustomer.Password}
          onChange={handleChange}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditUserForm = ({ user, onSave, onCancel }) => {
  const [editedUser, setEditedUser] = useState({ ...user });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    // Basic validation
    if (!editedUser.Name || !editedUser.Phone || !editedUser.Email) {
      setError("Name, Phone, and Email are required");
      return;
    }

    fetch(`/api/mobile/${user.UserID}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...editedUser, Password: undefined }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
        } else {
          onSave(editedUser);
        }
      })
      .catch((err) => {
        setError("An error occurred. Please try again.");
      });
  };

  return (
    <div>
      <TextField
        fullWidth
        margin="normal"
        label="Name"
        name="Name"
        value={editedUser.Name}
        onChange={handleChange}
      />
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          {" "}
          <TextField
            fullWidth
            margin="normal"
            label="Phone"
            name="Phone"
            value={editedUser.Phone}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="Email"
            type="email"
            value={editedUser.Email}
            onChange={handleChange}
          />
        </Grid2>
      </Grid2>

      <TextField
        fullWidth
        margin="normal"
        label="Organisation"
        name="Organisation"
        select
        SelectProps={{
          native: true,
        }}
        value={editedUser.Organisation}
        onChange={handleChange}
      >
        <option value="Individual">Individual</option>
        <option value="Organization">Organization</option>
      </TextField>

      <TextField
        fullWidth
        margin="normal"
        label="Status"
        name="Status"
        select
        SelectProps={{
          native: true,
        }}
        value={editedUser.Status}
        onChange={handleChange}
      >
        <option value={true}>Active</option>
        <option value={false}>Disabled</option>
      </TextField>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};
