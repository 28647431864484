import "../../Styles/tasks.scss";
import { MdOutlineAddTask, MdCategory, MdPersonAdd } from "react-icons/md";
import { BsFillPersonCheckFill } from "react-icons/bs";
import CustomPieChart from "../Stats/CustomPieChart";
import MyError from "../Util/MyError";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import { VscTasklist } from "react-icons/vsc";
import { BsEmojiSmile } from "react-icons/bs";
import { RiTaskLine } from "react-icons/ri";
import { ImStatsDots } from "react-icons/im";
import { GoTasklist } from "react-icons/go";
import { useEffect, useState } from "react";
import Select from "../Util/Select";
import RiderBody from "./RiderBody";
import { Box, Button, Typography } from "@mui/material";
import UploadFileDialog from "../Uber/CreateTask";
import { useNavigate } from "react-router-dom";
export default function Rider(props) {
  var jwt = require("jsonwebtoken");
  const [data, setData] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showing, setShowing] = useState(true);
  const [active, setActive] = useState("Active Task");
  const [refresh, setRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [id, setId] = useState(null);
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    fetch(`/api/uber/stats/top/Rider/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [id, active, refresh]);

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          navigate("/login");
        } else {
          setId(decoded.UserID);
          setUser(decoded.Name);
        }
      } catch (error) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    if (active === "Active Task") {
      setLoading(true);
      fetch(`/api/uber/admpaginated/Received/Rider/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (active === "On Hold") {
      setLoading(true);
      fetch(`/api/uber/admpaginated/On Hold/Rider/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      fetch(`/api/uber/admpaginated/Complete/Rider/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [offset, active, refresh, id]);

  return (
    <div className="tasks">
      <div className="welcome">
        <div>
          <h1>Welcome Back, {user}</h1>
          <p>
            Let's do our best today <BsEmojiSmile color="orange" />
          </p>
        </div>
        <div className="search">
          <Select data={["Title", "Client"]} />
          <input type="text" placeholder="Search ..." />
          <i className="fa fa-search"></i>
        </div>
      </div>
      <div className="intro">
        <h4>
          <span>
            <ImStatsDots size={24} color="orange" />
          </span>
          Rider's Check Summary
        </h4>
      </div>
      <div className="summary">
        <div className="inner">
          <div className="key">
            <p>Total</p>
            <p>New</p>
            <p>Completed</p>
          </div>
          <Keys
            total={data ? data?.total : 0}
            newu={data ? data?.newu : 0}
            completed={data ? data?.completed : 0}
          />
        </div>

        <div className="chart">
          <CustomPieChart
            data={[
              { name: "New", value: data ? data?.newu : 0 },
              { name: "Completed", value: data ? data?.completed : 0 },
            ]}
            color={["purple", "orange", "green"]}
            aspect={props.showing ? 1.5 : 0.8}
          />
          <h4>Performance Chart</h4>
        </div>
      </div>

      <br />
      <br />
      <div className="intro">
        <h4>
          <span>
            <GoTasklist size={24} color="orange" />
          </span>
          Rider Tasks
        </h4>
      </div>

      <Box className="selector" sx={{ marginY: 3 }}>
        <Box
          className="cont"
          sx={{
            display: "flex",
            gap: "2em",
          }}
        >
          <Selector
            txt="Active Tasks"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          />
          <Selector
            txt="On Hold"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          />
          <Selector
            txt="Completed Tasks"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          />
          <Box flexGrow={1}></Box>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="contained"
          >
            New
          </Button>
        </Box>
      </Box>

      <div className="tcontent">
        {tasks && tasks?.data?.length > 0 ? (
          tasks?.data?.map((item, index) => {
            return (
              <RiderBody
                item={item}
                key={index}
                task={true}
                refresh={refresh}
                setRefresh={setRefresh}
                type="rider"
                date={item.createdAt.split("T")[0]}
                file={item.NationalID}
              />
            );
          })
        ) : (
          <MyError txt="No Active Tasks" />
        )}
        {tasks && tasks?.data?.length > 0 && (
          <Pagination
            totalPages={Math.ceil(tasks.total / 12)}
            currentPage={offset + 1}
            handlePageChange={(v) => setOffset(v - 1)}
          />
        )}
      </div>
      <UploadFileDialog
        open={open}
        onClose={() => {
          setOpen(false);
          setRefresh(!refresh);
        }}
        category={props.url}
      />
      {loading && <WaveLoading />}
    </div>
  );
}

const Keys = (props) => {
  return (
    <div className="key">
      <div className="tt">
        <MdOutlineAddTask size={24} />
        <p>{props.total}</p>
      </div>
      <div className="tc">
        <MdCategory size={24} />
        <p>{props.newu}</p>
      </div>

      <div className="tf">
        <BsFillPersonCheckFill size={24} />
        <p>{props.completed}</p>
      </div>
    </div>
  );
};

const Selector = (props) => (
  <Box
    className={props.active === props.txt ? "active" : "item"}
    sx={{
      cursor: "pointer",
      display: "flex",
      gridTemplateColumns: "10px 1fr",
      gap: "10px",
      "&:hover": {
        h3: {
          color: "primary.main",
        },
        div: {
          backgroundColor: "secondary.main",
        },
      },
      ...(props.active === props.txt && {
        h3: {
          color: "primary.main",
        },
        div: {
          backgroundColor: "secondary.main",
        },
      }),
    }}
    onClick={() => {
      props.setActive(props.txt);
      props.setOffset(0);
    }}
  >
    <Box sx={{ width: "10px", height: "100%" }} />
    <Typography sx={{ my: "auto" }} variant="title">
      {props.txt}
    </Typography>
  </Box>
);
