import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  LinearProgress,
  Chip,
  Card,
} from "@mui/material";
import ReqeustPopup from "../Requests/RequestPopup";
import { useNavigate } from "react-router-dom";

export default function UberBody(props) {
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [showing, setShowing] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const navigate = useNavigate();
  useEffect(() => {
    const d = new Date(props.item.createdAt.replace(" ", "T"));
    setDate(d);
  }, [props.item]);

  useEffect(() => {
    if (props.item) {
      let c = 0;
      if (props.item.IDCheck != null) c += 1;
      if (props.item.PSVCheck != null) c += 1;
      if (props.item.DLCheck != null) c += 1;
      if (props.item.PoliceCheck != null) c += 1;
      setData(c);
    }
  }, [props.item]);

  function formatAMPM(date) {
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <>
      <Card
        onClick={() => {
          if (props.type === "driver") {
            navigate("/ubertask/" + props.item.DocumentID);
          } else {
            navigate("/ridertask/" + props.item.DocumentID);
          }
        }}
        sx={{
          mt: 2,
          cursor: "pointer",
          display: "flex",
          gap: 2,
          p: 2,
          alignItems: "center",
          borderRadius: "8px",
          boxShadow: "0px 8px 16px #60606040",
        }}
      >
        {/* Date Section */}
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "primary.main",
            color: "white",
            borderRadius: "5px",
            padding: 1,
            maxWidth: "120px",
          }}
        >
          <Typography variant="body2">
            {monthNames[date?.getMonth()]}, {date?.getDate()}{" "}
            {date?.getFullYear()} - {formatAMPM(date)}
          </Typography>
        </Box>

        <Box flexGrow={1}>
          <Typography variant="title">{props.item.Name}</Typography>
          <Typography variant="body2">{props.item.Phone}</Typography>
        </Box>
        <Typography variant="body2">
          <Chip label={props.item.Category} />
        </Typography>

        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h6">1 Day</Typography>
        </Box>

        {/* Progress Section */}
        <Box
          minWidth={100}
          display="flex"
          position="relative"
          alignItems="center"
        >
          <LinearProgress
            variant="determinate"
            value={data ? (data / 4) * 100 : 0}
            sx={{
              width: "100%",
              height: "32px",
              borderRadius: 1,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              zIndex: 1,
              color: "white",
              position: "absolute",
              width: "100%",
              textAlign: "center",
            }}
          >
            {((data / 4) * 100).toFixed(0)}%
          </Typography>
        </Box>
      </Card>

      {/* Popup Section */}
      {showing && !props.task && (
        <ReqeustPopup
          item={props.item}
          refresh={props.refresh}
          setRefresh={props.setRefresh}
          setShowing={setShowing}
        />
      )}
    </>
  );
}
