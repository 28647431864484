import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid2,
  Typography,
  Alert,
  Box,
  Card,
  IconButton,
  Divider,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const CreateUber = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Phone: "",
    Email: "",
    DOB: "",
    MPESA: "",
    IDNumber: "",
    Category: "",
  });
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInput1 = useRef();
  const fileInput2 = useRef();
  const fileInput3 = useRef();
  const items = ["New Driver", "Driver Renewal", "New Rider", "Rider Renewal"];
  const navigate = useNavigate();
  const isValidPhone = (phone) => {
    const phoneRegex = /^0\d{9}$/;
    return phoneRegex.test(phone);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setMsg("");

    if (!isValidPhone(formData.Phone)) {
      setMsg("Invalid phone number! It must be 10 digits and start with 0.");
      setLoading(false);
      return;
    }

    if (!isValidEmail(formData.Email)) {
      setMsg("Invalid email format!");
      setLoading(false);
      return;
    }

    if (
      !formData.Name ||
      !formData.Phone ||
      !formData.Email ||
      !formData.DOB ||
      !formData.IDNumber ||
      !formData.Category ||
      !fileInput1.current?.files[0] ||
      !fileInput2.current?.files[0] ||
      !fileInput3.current?.files[0]
    ) {
      setMsg("All fields are required!");
      setLoading(false);
      return;
    }

    const data = new FormData();
    data.append("Name", formData.Name);
    data.append("Phone", formData.Phone);
    data.append("Email", formData.Email);
    data.append("DOB", formData.DOB || null);
    data.append("MPESA", formData.MPESA || null);
    data.append("Category", formData.Category || null);
    data.append("IDNumber", formData.IDNumber || null);
    data.append("NationalID", fileInput1.current.files[0]);
    data.append("Passport", fileInput2.current.files[0]);
    data.append("Police", fileInput3.current.files[0]);

    fetch("/api/uber/createfromuser", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        if (result.success) {
          setMsg("User and Uber document created successfully!");
          setTimeout(() => {
            navigate("/uber/List");
          }, 500);
        } else {
          setMsg(result.error || "Something went wrong!");
        }
      })
      .catch((error) => {
        setLoading(false);
        setMsg("Error: " + error.message);
      });
  };

  return (
    <Box
      component={Card}
      sx={{ boxShadow: "0px 8px 16px #60606040", borderRadius: 2, p: 2 }}
    >
      <Box display="flex" gap={1}>
        <Typography variant="title" flexGrow={1}>
          Create New
        </Typography>
        <IconButton
          color="default"
          onClick={() => {
            navigate("/uber");
          }}
        >
          <ArrowBackIos />
        </IconButton>
      </Box>

      <Divider sx={{ my: 1 }} />

      <Box>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid2 mt={1} container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="Name"
                value={formData.Name}
                onChange={(e) =>
                  setFormData({ ...formData, Name: e.target.value })
                }
                required
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="ID Number"
                value={formData.IDNumber}
                onChange={(e) =>
                  setFormData({ ...formData, IDNumber: e.target.value })
                }
                required
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="Phone Number"
                value={formData.Phone}
                onChange={(e) =>
                  setFormData({ ...formData, Phone: e.target.value })
                }
                required
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="Email"
                value={formData.Email}
                onChange={(e) =>
                  setFormData({ ...formData, Email: e.target.value })
                }
                required
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formData.DOB}
                onChange={(e) =>
                  setFormData({ ...formData, DOB: e.target.value })
                }
                required
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                fullWidth
                label="MPESA Code"
                value={formData.MPESA}
                onChange={(e) =>
                  setFormData({ ...formData, MPESA: e.target.value })
                }
              />
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
              <Typography>Category *</Typography>
              <Select
                fullWidth
                value={formData.Category}
                onChange={(e) =>
                  setFormData({ ...formData, Category: e.target.value })
                }
                required
              >
                {items.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <Typography>National ID *</Typography>
              <input
                ref={fileInput1}
                type="file"
                accept=".pdf,.png,.jpg,.jpeg"
                style={{
                  display: "block",
                  padding: "16.5px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <Typography>Passport Photo *</Typography>
              <input
                ref={fileInput2}
                type="file"
                accept=".pdf,.png,.jpg,.jpeg"
                style={{
                  display: "block",
                  padding: "16.5px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <Typography>Police Clearance *</Typography>
              <input
                ref={fileInput3}
                type="file"
                accept=".pdf,.png,.jpg,.jpeg"
                style={{
                  display: "block",
                  padding: "16.5px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "4px",
                  width: "100%",
                }}
              />
            </Grid2>
          </Grid2>
          {msg && <Alert color="warning">{msg}</Alert>}
        </form>
      </Box>

      <Button
        variant="contained"
        type="submit"
        onClick={handleSubmit}
        disabled={loading}
        sx={{ display: "block", mx: "auto", my: 2 }}
      >
        {loading ? "Submitting..." : "Submit"}
      </Button>
    </Box>
  );
};

export default CreateUber;
