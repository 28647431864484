import "../../Styles/folders.scss";
import {
  faDownload,
  faFolder,
  faRefresh,
  faSearch,
  faTimes,
  faTimesCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import Loading from "../Util/Loading";
import Input from "../Util/Input";
import Button from "../Util/Button";
import {
  AiFillFileWord,
  AiFillFilePdf,
  AiFillVideoCamera,
  AiFillFileExcel,
  AiFillAppstore,
  AiFillFileZip,
} from "react-icons/ai";
import { BsFileEarmarkFill, BsImage } from "react-icons/bs";
import {
  Close,
  Download,
  FolderCopy,
  Refresh,
  Search,
  Upload,
} from "@mui/icons-material";

export default function Main(props) {
  const [data, setData] = useState(null);
  const [files, setFiles] = useState(null);
  const [paths, setPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [cfolder, setCFolder] = useState(false);
  const [cfile, setCFile] = useState(false);

  useEffect(() => {
    if (props.path) {
      const p = props.path.split("/");
      setPaths(p);
      setData(null);
      setLoading(true);
      let path = encodeURIComponent(props.path);
      path = encodeURIComponent(path);
      fetch(`/api/folders/byroot/${encodeURIComponent(path)}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [props.path, refresh]);

  useEffect(() => {
    if (props.path) {
      setFiles(null);
      setLoading(true);
      let path = encodeURIComponent(props.path);
      path = encodeURIComponent(path);
      fetch(`/api/files/byroot/${encodeURIComponent(path)}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setFiles(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [props.path, refresh]);

  function quickSearch(value) {
    setLoading(true);
    let path = encodeURIComponent(props.path);
    path = encodeURIComponent(path);
    setData(null);
    setFiles(null);
    fetch(`/api/folders/quicksearch/${encodeURIComponent(path)}/${value}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setFiles(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <div className="folders">
      <div className="wrap">
        <div className="top">
          <p
            onClick={() => {
              setCFolder(true);
            }}
          >
            <FolderCopy icon={faFolder} /> New Folder
          </p>
          <p
            onClick={() => {
              setCFile(true);
            }}
          >
            <Upload icon={faUpload} /> Upload
          </p>
          <div className="search">
            <input
              type="text"
              onChange={(e) => {
                const value = e.target.value;
                if (value != "") {
                  quickSearch(value);
                } else {
                  setRefresh(!refresh);
                }
              }}
            />
            <Search className="fa-search" icon={faSearch} />
          </div>
        </div>

        <div className="list">
          <div className="path">
            {paths.map((item, i) => {
              return (
                <PathItem
                  key={i}
                  paths={paths}
                  item={item}
                  setPath={props.setPath}
                />
              );
            })}
            <Refresh
              onClick={() => {
                setRefresh(!refresh);
              }}
              icon={faRefresh}
              className="fa-refresh"
            />
          </div>

          <hr />
          {data &&
            data.length > 0 &&
            data.map((item, i) => {
              return (
                <Folder
                  path={props.path}
                  setPath={props.setPath}
                  setLoading={setLoading}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  key={i}
                  item={item}
                />
              );
            })}
          {files &&
            files.length > 0 &&
            files.map((item, i) => {
              return (
                <File
                  path={props.path}
                  setPath={props.setPath}
                  setLoading={setLoading}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  key={i}
                  item={item}
                />
              );
            })}
          {cfolder && (
            <CreateFolder
              setData={setData}
              setLoading={setLoading}
              setCFolder={setCFolder}
              path={props.path}
            />
          )}
          {cfile && (
            <CreateFile
              setFiles={setFiles}
              setLoading={setLoading}
              setRefresh={setRefresh}
              refresh={refresh}
              setCFile={setCFile}
              path={props.path}
            />
          )}
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
}

const PathItem = (props) => {
  return (
    <div className="pathitem">
      <p
        onClick={() => {
          const index = props.paths
            .map((e) => {
              return e;
            })
            .indexOf(props.item);
          let url = "";
          for (let i = 0; i <= index; i++) {
            if (index != 0) {
              url += props.paths[i] + "/";
            } else url += props.paths[i];
          }
          if (index != 0) {
            url = url.substring(0, url.length - 1);
          }

          props.setPath(url);
          localStorage.setItem("path", url);
        }}
      >
        {props.item}
      </p>
    </div>
  );
};

const Folder = (props) => {
  const [hasFiles, setHasFiles] = useState(true);
  useEffect(() => {
    let path = decodeURIComponent(props.item.Root);
    path = path + "/" + props.item.Folder;

    path = encodeURIComponent(path);

    fetch(`/api/folders/checkfiles/${encodeURIComponent(path)}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.files > 0) {
          setHasFiles(true);
        } else setHasFiles(false);
      })
      .catch((e) => {});
  }, []);

  function deleteFolder() {
    props.setLoading(true);
    fetch(`/api/folders/${props.item.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }
  return (
    <div className="fld">
      <FolderCopy
        onClick={() => {
          const path = props.path + "/" + props.item.Folder;
          localStorage.setItem("path", path);
          props.setPath(path);
        }}
        className="fd-icon"
        icon={faFolder}
      />
      <p
        onClick={() => {
          const path = props.path + "/" + props.item.Folder;
          props.setPath(path);
          localStorage.setItem("path", path);
        }}
      >
        {props.item.Folder}
      </p>
      {!hasFiles && (
        <Close
          onClick={() => {
            deleteFolder();
          }}
          className="fa-times"
          icon={faTimesCircle}
        />
      )}
    </div>
  );
};

const File = (props) => {
  const [FileIcon, setFileIcon] = useState(
    <BsFileEarmarkFill color="gray" className="fd-icon" />
  );

  useEffect(() => {
    if (props.item) {
      const ext = props.item?.FileName.split(".");
      const index = ext.length - 1;

      switch (ext[index]) {
        case "pdf":
          setFileIcon(<AiFillFilePdf color="red" className="fd-icon" />);
          break;
        case "doc":
          setFileIcon(<AiFillFileWord color="red" className="fd-icon" />);
          break;
        case "docx":
          setFileIcon(<AiFillFileWord color="blue" className="fd-icon" />);
          break;
        case "xls":
          setFileIcon(<AiFillFileExcel color="green" className="fd-icon" />);
          break;
        case "xlsx":
          setFileIcon(<AiFillFileExcel color="green" className="fd-icon" />);
          break;
        case "csv":
          setFileIcon(<AiFillFileExcel color="green" className="fd-icon" />);
          break;
        case "jpg":
          setFileIcon(<BsImage color="#87C4FF" className="fd-icon" />);
          break;
        case "svg":
          setFileIcon(<BsImage color="#87C4FF" className="fd-icon" />);
          break;
        case "png":
          setFileIcon(<BsImage color="#87C4FF" className="fd-icon" />);
          break;
        case "mp4":
          setFileIcon(
            <AiFillVideoCamera color="#87C4FF" className="fd-icon" />
          );
          break;
        case "mp4":
          setFileIcon(
            <AiFillVideoCamera color="#87C4FF" className="fd-icon" />
          );
          break;
        case "exe":
          setFileIcon(<AiFillAppstore color="#427D9D" className="fd-icon" />);
          break;
        case "zip":
          setFileIcon(<AiFillFileZip color="#706233" className="fd-icon" />);
          break;
        case "rar":
          setFileIcon(<AiFillFileZip color="#706233" className="fd-icon" />);
          break;
        default:
          setFileIcon(<BsFileEarmarkFill color="gray" className="fd-icon" />);
          break;
      }
    }
  }, [props?.item]);

  function deleteFile() {
    props.setLoading(true);
    fetch(`/api/files/${props.item.ID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }

  function downloadDoc() {
    props.setLoading(true);
    let url = decodeURIComponent(props.item.Path);
    url = url + "/" + props.item.FileName;
    url = encodeURIComponent(url);

    fetch(`/api/uploads/${encodeURIComponent(url)}`)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else throw Error("");
      })
      .then((blob) => {
        props.setLoading(false);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = props.item.FileName;

        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((e) => {
        props.setLoading(false);
        alert("File not found!");
      });
  }

  function previewDoc() {
    props.setLoading(true);
    let url = decodeURIComponent(props.item.Path);
    url = url + "/" + props.item.FileName;
    url = encodeURIComponent(url);

    fetch(`/api/uploads/${encodeURIComponent(url)}`)
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else throw Error("");
      })
      .then((blob) => {
        props.setLoading(false);
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
      .catch((e) => {
        props.setLoading(false);
        alert("File not found!");
      });
  }
  return (
    <div className="fl" title={decodeURIComponent(props.item.Path)}>
      {FileIcon}
      <p
        onClick={() => {
          previewDoc();
        }}
      >
        {props.item.FileName}
      </p>
      <Download
        onClick={() => {
          downloadDoc();
        }}
        className="fa-download"
        icon={faDownload}
      />
      <Close
        onClick={() => {
          deleteFile();
        }}
        className="fa-times"
        icon={faTimesCircle}
      />
    </div>
  );
};

const CreateFolder = (props) => {
  const [error, setError] = useState("");
  const v = useRef();

  function hasSpecialCharacters(inputString) {
    const specialCharacterPattern = /[^A-Za-z0-9\s]/;
    return specialCharacterPattern.test(inputString);
  }

  function createFolder(value) {
    props.setLoading(true);
    fetch(`/api/folders/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        Folder: value,
        Root: encodeURIComponent(props?.path),
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        if (data?.length > 0) {
          props.setData(data);
          props.setCFolder(false);
        } else setError(data.error);
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }

  return (
    <div className="popup">
      <div className="pwrap">
        <div className="hd">
          <h3>New Folder</h3>
          <Close
            onClick={() => {
              props.setCFolder(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>
        <hr />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input ref={v} label="Folder Name" />
          <h6>{error}</h6>
          <Button
            handleClick={() => {
              const value = v.current.value;
              if (value != "") {
                if (hasSpecialCharacters(value)) {
                  setError("Name cannot have special characters");
                } else createFolder(v.current.value);
              } else setError("Folder name is required!");
            }}
            value="Create"
          />
        </form>
      </div>
    </div>
  );
};

const CreateFile = (props) => {
  const [error, setError] = useState("");
  const v = useRef();

  function createFolder(files) {
    props.setLoading(true);
    const formData = new FormData();
    formData.append(`Path`, encodeURIComponent(props.path));
    for (let i = 0; i < files.length; i++) {
      formData.append(`Files`, files[i]);
    }

    fetch(`/api/files/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        if (data.success != null) {
          setError(data.success);
          setTimeout(() => {
            props.setRefresh(!props.refresh);
            props.setCFile(false);
          }, 1000);
        } else {
          setError(data.error);
          setTimeout(() => {
            props.setRefresh(!props.refresh);
            props.setCFile(false);
          }, 1000);
        }
      })
      .catch((e) => {
        props.setLoading(false);
      });
  }

  return (
    <div className="popup">
      <div className="pwrap">
        <div className="hd">
          <h3>Upload File(s)</h3>
          <Upload
            onClick={() => {
              props.setCFile(false);
            }}
            className="fa-times"
            icon={faTimes}
          />
        </div>
        <hr />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="input">
            <label htmlFor="input">Select File(s)</label>
            <input ref={v} type="file" multiple />
          </div>

          <h6>{error}</h6>
          <Button
            handleClick={() => {
              const value = v.current.files;

              if (value.length > 0) {
                createFolder(value);
              } else setError("Select at least 1 file");
            }}
            value="Upload"
          />
        </form>
      </div>
    </div>
  );
};
