import { useEffect, useState } from "react";
import ReqeustPopup from "../Requests/RequestPopup";
import TaskPopup from "../Tasks/TaskPopup";
import { useNavigate } from "react-router-dom";
export default function AccordionBody(props) {
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [days, setDays] = useState(0);
  const [showing, setShowing] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const navigate = useNavigate();

  useEffect(() => {
    const d = new Date(props.item.createdAt.replace(" ", "T"));
    setDate(d);
  }, []);

  useEffect(() => {
    if (props.item) {
      let c = 0;
      if (props.item.Received) c += 1;
      if (props.item.Checking) c += 1;
      if (props.item.ReportSubmitted) c += 1;
      if (props.item.Assigned) c += 1;
      setData(c);
      if (!props.item?.ReportSubmitted) {
        const createdAtDate = new Date(props.item?.createdAt);
        createdAtDate.setDate(createdAtDate.getDate() + 5);
        const currentDate = new Date();
        const timeDifference = createdAtDate.getTime() - currentDate.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        setDays(Math.round(daysDifference));
      } else {
        setDays(0);
      }
    }
  }, [props.item]);

  function formatAMPM(date) {
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return (
    <>
      <div
        onClick={() => {
          if (!props.task) {
            setShowing(true);
          } else {
            navigate("/singletask/" + props.item.DocumentID);
          }
        }}
        className="accordion-body"
      >
        <div className="date">
          <div>
            <h1>{date?.getDate()}</h1>
            <p>
              {monthNames[date?.getMonth()]} {date?.getFullYear()}
            </p>
            <p>{formatAMPM(date)}</p>
          </div>
        </div>
        <div className="details">
          <h4>{props.item.Title}</h4>
          <p>Organization: {props.item.Organisation}</p>
          <p>Contact Person: {props.item.Name}</p>
          <p>Subjects: {props.item.Clients ? props.item.Clients.length : 0}</p>
        </div>
        <div className="deadline">
          <h4 style={{ color: days < 0 ? "red" : "green" }}>
            {days} Day{Math.abs(days) == 1 ? "" : "s"}
          </h4>
        </div>
        <div className="progress">
          <div
            style={{
              height: data ? `${((data / 4) * 100).toFixed(0)}%` : "0%",
            }}
            className="prg"
          ></div>
          <p>
            <span>{((data / 4) * 100).toFixed(0)}%</span>
          </p>
        </div>
      </div>
      {showing && (
        <>
          {!props.task && (
            <ReqeustPopup
              item={props.item}
              refresh={props.refresh}
              setRefresh={props.setRefresh}
              setShowing={setShowing}
            />
          )}
        </>
      )}
    </>
  );
}
