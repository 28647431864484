import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import {
  Box,
  Typography,
  IconButton,
  Grid2,
  Divider,
  Card,
  Button,
} from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CustomBarChart from "../Stats/CustomBarChart";
import MyError from "../Util/MyError";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import UberBody from "./UberBody";
import { Create } from "@mui/icons-material";
import CreateTask from "./CreateTask";
import { useNavigate } from "react-router-dom";

export default function Uber(props) {
  var jwt = require("jsonwebtoken");
  const [data, setData] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showing, setShowing] = useState(true);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("Active Tasks");
  const [refresh, setRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [id, setId] = useState(null);
  const [user, setUser] = useState("");
  const [type, setType] = useState("driver");
  const navigate = useNavigate();

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    if (props.url.includes("Driver")) {
      setType("driver");
    } else {
      setType("rider");
    }
  }, []);

  useEffect(() => {
    if (props.url) {
      fetch(`/api/uber/stats/top/${props.url}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setData(data);
        })
        .catch((e) => {});
    }
  }, [props.url, refresh]);

  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          navigate("/login");
        } else {
          setId(decoded.UserID);
          setUser(decoded.Name);
        }
      } catch (error) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    setTasks(null);
    if (active === "Active Tasks") {
      setLoading(true);

      fetch(`/api/uber/admpaginated/Received/${props.url}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (active === "On Hold") {
      setLoading(true);
      fetch(`/api/uber/admpaginated/On Hold/${props.url}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      fetch(`/api/uber/admpaginated/Complete/${props.url}/${offset * 12}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Could not fetch messages!!!");
          }
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          setTasks(data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [offset, active, refresh, id, props.url]);

  const Item = ({ txt }) => (
    <Box
      onClick={() => props.setSelected(txt)}
      sx={{
        cursor: "pointer",
        display: "grid",
        gridTemplateColumns: "10px 1fr",
        gap: "10px",
        "&:hover": {
          h3: {
            color: "primary.main",
          },
          div: {
            backgroundColor: "secondary.main",
          },
        },
        ...(txt === props.active && {
          h3: {
            color: "primary.main",
          },
          div: {
            backgroundColor: "secondary.main",
          },
        }),
      }}
    >
      <Box sx={{ width: "10px", height: "100%" }} />
      <Typography variant="h6" sx={{ fontWeight: 560, fontSize: "small" }}>
        {txt}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ position: "relative" }}>
      {/* Task Stats Section */}

      {/* Task Selector */}
      <Box className="selector" sx={{ marginY: 3 }}>
        <Box
          className="cont"
          sx={{
            display: "flex",
            gap: "2em",
          }}
        >
          <Selector
            txt="Active Tasks"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          />
          <Selector
            txt="On Hold"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          />
          <Selector
            txt="Completed Tasks"
            active={active}
            setActive={setActive}
            setOffset={setOffset}
          />
          <Box flexGrow={1}></Box>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="contained"
          >
            New
          </Button>
        </Box>
      </Box>

      {/* Task Content */}
      <Box className="tcontent" sx={{ marginY: 3 }}>
        {tasks && tasks?.data?.length > 0 ? (
          tasks?.data?.map((item, index) => (
            <UberBody
              item={item}
              key={index}
              task={true}
              refresh={refresh}
              setRefresh={setRefresh}
              type={type}
              date={item.createdAt.split("T")[0]}
              file={item.NationalID}
            />
          ))
        ) : (
          <MyError txt="No Active Tasks" />
        )}
        {tasks && tasks?.data?.length > 0 && (
          <Pagination
            totalPages={Math.ceil(tasks.total / 12)}
            currentPage={offset + 1}
            handlePageChange={(v) => setOffset(v - 1)}
          />
        )}
      </Box>
      {loading && <WaveLoading />}
    </Box>
  );
}

const Selector = (props) => (
  <Box
    className={props.active === props.txt ? "active" : "item"}
    sx={{
      cursor: "pointer",
      display: "flex",
      gridTemplateColumns: "10px 1fr",
      gap: "10px",
      "&:hover": {
        title: {
          color: "secondary",
        },
        div: {
          backgroundColor: "secondary.main",
        },
      },
      ...(props.active === props.txt && {
        title: {
          color: "secondary",
        },
        div: {
          backgroundColor: "secondary.main",
        },
      }),
    }}
    onClick={() => {
      props.setActive(props.txt);
      props.setOffset(0);
    }}
  >
    <Box sx={{ width: "10px", height: "100%" }} />
    <Typography
      sx={{
        my: "auto",
        color: props.active == props.txt ? "#281e58" : "#2863ac",
      }}
      variant="subtitle"
    >
      {props.txt}
    </Typography>
  </Box>
);
