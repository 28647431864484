import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Chip,
  CircularProgress,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

import {
  faUser,
  faSearch,
  faUserPlus,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useNavigate } from "react-router-dom";
import { Add, Person, Sync } from "@mui/icons-material";

export default function Invoicing(props) {
  const [data, setData] = useState(null);
  const [user, setUser] = useState("");
  const theme = useTheme();
  const [column, setColumn] = useState("InvoiceNo");
  const [searchValue, setSearchValue] = useState("");
  const [changed, setChanged] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    { field: "updatedAt", sort: "desc" },
  ]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateColumn, setDateColumn] = useState("updatedAt");
  const navigate = useNavigate();

  useEffect(() => {
    if (props.user) {
      try {
        if (props.user.Role !== "Admin" && props.user.Role !== "Super Admin") {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const fetchInvoices = async (value = searchValue) => {
    setLoading(true);
    const orderColumn = sortModel[0]?.field || "InvoiceNo";
    const orderType = sortModel[0]?.sort || "asc";

    let url = `/api/invoices/paginated?offset=${
      offset * limit
    }&limit=${limit}&orderColumn=${orderColumn}&orderType=${orderType}&dateColumn=${dateColumn}`;

    if (startDate) {
      url += `&startDate=${startDate.toISOString()}`;
    }
    if (endDate) {
      url += `&endDate=${endDate.toISOString()}`;
    }

    if (value) {
      url += `&${column}=${value}`;
    }

    try {
      const response = await fetch(url, {
        method: "get",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setData(data);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, [offset, limit, sortModel, startDate, endDate, dateColumn]);

  const handleSearch = (value) => {
    setSearchValue(value);
    setOffset(0);
    fetchInvoices(value);
  };

  const handleRowClick = (params) => {
    const invoiceId = params.row.ID;
    navigate(`/invoices/preview?id=${invoiceId}`);
  };

  const columns = [
    { field: "InvoiceNo", headerName: "No", flex: 0.5 },
    {
      field: "Mobile.Name",
      headerName: "Client",
      flex: 1,
      renderCell: (params) => <>{params.row.Mobile.Name}</>,
    },
    {
      field: "Mobile.Phone",
      headerName: "Contact",
      flex: 1,
      renderCell: (params) => (
        <>
          <Person
            icon={faUser}
            style={{ marginRight: "10px", color: theme.palette.primary.main }}
          />
          {params.row.Mobile.Phone}
        </>
      ),
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => (
        <>
          <Person
            icon={faUser}
            style={{ marginRight: "10px", color: theme.palette.primary.main }}
          />
          {params.row.Mobile.Email}
        </>
      ),
    },
    { field: "Date", headerName: "Due Date", flex: 0.7 },
    {
      field: "Status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <Chip
          size="small"
          label={params.value ? "Active" : "Inactive"}
          color={params.value ? "primary" : "warning"}
        />
      ),
    },
    {
      field: "Balance",
      headerName: "Balance",
      flex: 0.7,
      renderCell: (params) => (
        <Box
          component="span"
          sx={{
            color: parseInt(params.value) <= 0 ? "green" : "orange",
            width: "100%",
            marginLeft: "auto",
            display: "block",
            px: 1,
            py: 0.5,
            borderRadius: 1,
            textAlign: "right",
            fontSize: "medium",
          }}
        >
          {parseFloat(params.value).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </Box>
      ),
    },
    {
      field: "Total",
      headerName: "Invoice Amount",
      flex: 0.7,
      renderCell: (params) => (
        <Box
          component="span"
          sx={{
            color: params.row.Balance > 0 ? "orangered" : "green",
            px: 1,
            py: 0.5,
            borderRadius: 1,
            textAlign: "right",
            fontSize: "medium",
            fontWeight: "bold",
            marginLeft: "auto",
            display: "block",
          }}
        >
          {parseFloat(params.value).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ padding: 0 }}>
      <Box display="flex" gap={2} mb={2} flexWrap="wrap">
        <Box flexGrow={1}></Box>
        <FormControl sx={{ minWidth: { xs: "100%", md: "150px" } }}>
          <InputLabel size="small">Date Column</InputLabel>
          <Select
            label="Date Column"
            onChange={(e) => setDateColumn(e.target.value)}
            value={dateColumn}
          >
            <MenuItem value="createdAt">Created At</MenuItem>
            <MenuItem value="updatedAt">Updated At</MenuItem>
            <MenuItem value="Date">Due Date</MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{ width: "60px", padding: 0 }}
              />
            )}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputBase-root": {
                    padding: "4px", // Adjust padding inside the input
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "8px", // Adjust icon size
                  },
                }}
              />
            )}
            components={{
              OpenPickerIcon: (props) => (
                <IconButton
                  {...props}
                  sx={{ padding: "4px", fontSize: "16px" }}
                />
              ),
            }}
          />
        </LocalizationProvider>
        <IconButton
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
            fetchInvoices();
          }}
          color="primary"
        >
          <Sync icon={faSync} />
        </IconButton>
      </Box>
      <Card
        sx={{ boxShadow: "0px 16px 32px #60606040", borderRadius: "8px", p: 2 }}
      >
        <Box display="flex" gap={2} mb={2} flexWrap="wrap">
          <Typography flexGrow={1} variant="h5" gutterBottom>
            Invoice List
          </Typography>
          <Box flexGrow={1}></Box>
          <FormControl sx={{ minWidth: { xs: "100%", md: "150px" } }}>
            <InputLabel size="small">Search by...</InputLabel>
            <Select
              label="Search by..."
              size="small"
              onChange={(e) => setColumn(e.target.value)}
              value={column}
            >
              <MenuItem value="InvoiceNo">Invoice No</MenuItem>
              <MenuItem value="Date">Date</MenuItem>
              <MenuItem value="Title">Title</MenuItem>
              <MenuItem value="Total">Total</MenuItem>
              <MenuItem value="Company">Company</MenuItem>
              <MenuItem value="Address">Address</MenuItem>
              <MenuItem value="ContactPersonName">Contact Person Name</MenuItem>
              <MenuItem value="ContactPersonPhone">
                Contact Person Phone
              </MenuItem>
              <MenuItem value="ContactPersonEmail">
                Contact Person Email
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            size="small"
            label="Search..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", color: "white" }}
            startIcon={<Add icon={faUserPlus} />}
            onClick={() => navigate("/invoices/new")}
          >
            New Invoice
          </Button>
        </Box>

        {loading ? (
          <Box
            sx={{ display: "grid", placeContent: "center", minHeight: "30vh" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={data?.data || []}
            columns={columns}
            pageSize={limit}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onPageSizeChange={(newLimit) => {
              setLimit(newLimit);
              setOffset(0);
            }}
            autoHeight
            disableSelectionOnClick
            onPageChange={(newPage) => setOffset(newPage)}
            pagination
            getRowId={(row) => row.ID}
            onSortModelChange={(model) => setSortModel(model)}
            onRowClick={handleRowClick}
            sx={{ width: "100%" }}
          />
        )}
      </Card>
    </Box>
  );
}
