import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Divider,
  IconButton,
  Button,
  Card,
  ButtonGroup,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import QueryBuilder from "./QueryBuilder";
import Uber from "./Uber";
import UberData from "./UberData";

export default function UberList(props) {
  const [active, setActive] = useState("New Drivers");
  var jwt = require("jsonwebtoken");
  const [user, setUser] = useState("");
  const [refresh, setRefresh] = useState(false);
  const items = [
    "New Drivers",
    "Driver Renewals",
    "New Riders",
    "Rider Renewals",
    "Data",
  ];
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("gfgfgggn");
    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          navigate("/login");
        } else {
          setUser(decoded.Name);
        }
      } catch (error) {
        navigate("/login");
      }
    } else {
      navigate("/login"); 
    }
  }, []);

  return (
    <Box
      p={2}
      component={Card}
      sx={{
        position: "relative",
        boxShadow: "0px 8px 16px #60606040",
        borderRadius: 2,
      }}
    >
      {/* Navigation Items */}
      <Box sx={{ backgroundColor: "white", my: 2 }}>
        <ButtonGroup color="primary">
          {items.map((txt) => (
            <Button
              key={txt}
              variant={active === txt ? "contained" : "outlined"}
              color={active === txt ? "primary" : "default"}
              onClick={() => setActive(txt)}
              sx={{
                textTransform: "capitalize",
              }}
            >
              {txt}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      {/* Conditional Render based on Active Item */}
      {active === "New Drivers" && (
        <Uber url="New Driver" setSelected={setActive} active={active} />
      )}
      {active === "Driver Renewals" && (
        <Uber url="Driver Renewal" setSelected={setActive} active={active} />
      )}
      {active === "New Riders" && (
        <Uber url="New Rider" setSelected={setActive} active={active} />
      )}
      {active === "Rider Renewals" && (
        <Uber url="Rider Renewal" setSelected={setActive} active={active} />
      )}
      {active === "Data" && (
        <UberData setSelected={setActive} active={active} />
      )}
    </Box>
  );
}
